import React, { memo, useCallback } from 'react';
//
import { numWord } from '@helpers/index';
import { useAppDispatch } from '@hooks/index';
import { TSimpleStringObjLevel2 } from '@models/index';
import { CLIENTS_EVENTS, clientsEvents } from '@const/task';
import { ClientEventBlock } from '@blocks/clientEventBlock';
import { DropdownWithIcon } from '@components/dropdownWithIcon';
import { ServiceEmployeeInputs } from '@blocks/serviceEmployeeInputs';
import { addTPBalloon, deleteTPBalloon, setVisitQuantity } from '@redux/Task/slice';
import {
  TTPNameList,
  TOnChangeHandler,
  TDeleteBalloonSet,
  TVisitQuantityOnChangeHandler,
} from '@shared/types';

interface IClientsBlockProps {
  /**
   * Массив значений фильтра услуг
   * @param {Array<string>}
   */
  services: Array<string>;
  /**
   * Новое значение фильтра услуг
   * @param {string}
   */
  newServicesValue: string;
  /**
   * Массив значений фильтра сотрудников
   * @param {Array<string>}
   */
  staff: Array<string>;
  /**
   * Новое значение фильтра сотрудников
   * @param {string}
   */
  newStaffValue: string;
  /**
   * Массив значений блока визиты, первое значение в массиве это quantityFrom, второе quantityTo
   * @param {Array<string>}
   */
  visits: Array<string>;
  /**
   * Значение инпута клиент не записан
   * @param {string}
   */
  notRecord: string;
  /**
   * Значение инпута клиенту не ответили
   * @param {string}
   */
  lateReply: string;
  /**
   * Время постановки format HH:MM
   * @param {string}
   */
  sendingTime: string;
  /**
   * Значение выбранного события
   * @param {string}
   */
  selectedClientEvent: string;
  /**
   * Значение инпута клиент не приходил
   * @param {string}
   */
  daysWithoutVisiting: string;
  /**
   * Callback для обработки изменений в инпуте клиент не записан
   * @param {TOnChangeHandler}
   */
  setNotRecordLimit: TOnChangeHandler;
  /**
   * Callback для обработки изменений в инпуте клиенту не ответили
   * @param {TOnChangeHandler}
   */
  setLateReplyLimit: TOnChangeHandler;
  /**
   * Callback для установки времени постановки задачи
   * @param {(value: string) => void}
   */
  setWhenTime: (value: string) => void;
  /**
   * Callback для обработки изменений в инпуте клиент не приходил
   * @param {TOnChangeHandler}
   */
  setNoVisitsDaysHandler: TOnChangeHandler;
  /**
   * Callback для установки типа события
   * @param {(clientEventsType: string) => () => void}
   */
  setSelectedEvent: (clientEventsType: string) => () => void;
  /**
   * ID выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Объект с данными сотрудников и услуг
   * @param {TSimpleStringObjLevel2}
   */
  categoriesDataList: TSimpleStringObjLevel2;
  /**
   * Флаг успешного получения данных страницы настроек
   * @param {boolean}
   */
  isSuccess: boolean;
  daysAfterVisit: string;
  setDaysAfterVisitsHandler: TOnChangeHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const ClientsBlock = memo(
  ({
    staff,
    visits,
    services,
    notRecord,
    lateReply,
    isSuccess,
    setWhenTime,
    sendingTime,
    newStaffValue,
    daysAfterVisit,
    setSelectedEvent,
    newServicesValue,
    setLateReplyLimit,
    setNotRecordLimit,
    categoriesDataList,
    daysWithoutVisiting,
    selectedClientEvent,
    selectedFilialAccId,
    setNoVisitsDaysHandler,
    setDaysAfterVisitsHandler,
    className = '',
  }: IClientsBlockProps) => {
    const dispatch = useAppDispatch();

    // Удаляет 'шарик' по его значению
    const deleteBalloon: TDeleteBalloonSet = useCallback(
      (value, keyName) => () => {
        const tKeyName = keyName as TTPNameList;
        dispatch(deleteTPBalloon({ keyName: tKeyName, value }));
      },
      [dispatch],
    );

    // Добавляет новый по событию Blur шарик и очищает инпут
    const addBalloonOnBlurHandler: (name: string, value: string) => void = useCallback(
      (name, value) => {
        const keyName = name as TTPNameList;
        dispatch(addTPBalloon({ keyName, value }));
      },
      [dispatch],
    );

    // Обрабатывает изменения количества визитов
    const visitQuantityOnChangeHandler: TVisitQuantityOnChangeHandler = useCallback(
      ({ newQuantityFromValue, newQuantityToValue }) => {
        dispatch(
          setVisitQuantity({
            quantityFrom: newQuantityFromValue,
            quantityTo: newQuantityToValue,
          }),
        );
      },
      [dispatch],
    );

    return (
      <div className={`flex flex-col ${className}`}>
        <div className=''>
          <DropdownWithIcon
            items={clientsEvents}
            callBack={setSelectedEvent}
            selectedItem={selectedClientEvent}
            className='w-full max-w-[47rem]'
          />
        </div>
        {selectedClientEvent === CLIENTS_EVENTS.LOST ? (
          <>
            <ClientEventBlock
              sendingTime={sendingTime}
              setWhenTime={setWhenTime}
              inputValue={daysWithoutVisiting}
              changeInputValueCallback={setNoVisitsDaysHandler}
              textBeforeInput='Клиент не приходит'
              textAfterInput={numWord(Number(daysWithoutVisiting), ['день', 'дня', 'дней'])}
              className='mt-4'
            />
            <ServiceEmployeeInputs
              staff={staff}
              visits={visits}
              services={services}
              isSuccess={isSuccess}
              newStaffValue={newStaffValue}
              deleteBalloon={deleteBalloon}
              newServicesValue={newServicesValue}
              categoriesDataList={categoriesDataList}
              selectedFilialAccId={selectedFilialAccId}
              addBalloonOnBlurHandler={addBalloonOnBlurHandler}
              visitQuantityOnChangeHandler={visitQuantityOnChangeHandler}
            />
          </>
        ) : null}
        {selectedClientEvent === CLIENTS_EVENTS.AFTER_VISIT ? (
          <>
            <ClientEventBlock
              sendingTime={sendingTime}
              setWhenTime={setWhenTime}
              inputValue={daysAfterVisit}
              textBeforeInput='После визита'
              changeInputValueCallback={setDaysAfterVisitsHandler}
              textAfterInput={numWord(Number(daysAfterVisit), ['день', 'дня', 'дней'])}
              className='mt-4'
            />
            <ServiceEmployeeInputs
              staff={staff}
              visits={visits}
              services={services}
              isSuccess={isSuccess}
              newStaffValue={newStaffValue}
              deleteBalloon={deleteBalloon}
              newServicesValue={newServicesValue}
              categoriesDataList={categoriesDataList}
              selectedFilialAccId={selectedFilialAccId}
              addBalloonOnBlurHandler={addBalloonOnBlurHandler}
              visitQuantityOnChangeHandler={visitQuantityOnChangeHandler}
            />
          </>
        ) : null}
        {selectedClientEvent === CLIENTS_EVENTS.NOT_RECORD ? (
          <ClientEventBlock
            isNoTime
            sendingTime={sendingTime}
            setWhenTime={setWhenTime}
            inputValue={notRecord}
            changeInputValueCallback={setNotRecordLimit}
            textBeforeInput='Клиент не записан в течении'
            textAfterInput={numWord(Number(notRecord), ['минуты', 'минут', 'минут'])}
            className='mt-4'
          />
        ) : null}
        {selectedClientEvent === CLIENTS_EVENTS.LATE_REPLY ? (
          <ClientEventBlock
            isNoTime
            sendingTime={sendingTime}
            setWhenTime={setWhenTime}
            inputValue={lateReply}
            changeInputValueCallback={setLateReplyLimit}
            textBeforeInput='Клиенту не ответили в течении'
            textAfterInput={numWord(Number(lateReply), ['минуты', 'минут', 'минут'])}
            className='mt-4'
          />
        ) : null}
      </div>
    );
  },
);
