import React, { memo, useMemo, useState } from 'react';
//
import { Icon } from '@atoms/icon';
import { Callout } from '@uikit/Callout';
import { KeyText } from '@blocks/keyText';
import { TSimpleStringObj } from '@models/index';

interface ITaskAdditionalInfoProps {
  /**
   * Опциональный параметр изменяет внешний вид компонента.
   * @param {boolean}
   */
  isWr?: boolean;
  /**
   * Параметр изменяет внешний вид компонента, включает отображение для мобилок
   * @param {boolean}
   */
  forMobile: boolean;
  /**
   * Callback для добавления ключа в текст
   * @param {(key: string) => () => void}
   */
  setKeyInTextarea: (key: string) => () => void;
  /**
   * Объект с ключами
   * @param {TSimpleStringObj}
   */
  dictForKeys: TSimpleStringObj;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const TaskAdditionalInfo = memo(
  ({
    isWr,
    forMobile,
    dictForKeys,
    setKeyInTextarea,
    className = '',
  }: ITaskAdditionalInfoProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const onclickHandler = () => setIsOpen(prevState => !prevState);

    const addStyle = forMobile ? 'hidden lg: flex' : 'flex';

    const keysToRender = Object.entries(dictForKeys).map(item => (
      <span
        key={item[0]}
        className='flex leading-6 mb-[0.3125rem]'
        title='Щелкните по тексту для добавления в сообщение'>
        <KeyText keyName={`{${item[0]}}`} callBack={setKeyInTextarea(`{${item[0]}}`)} />
        &nbsp;-
        <span className='list-disc whitespace-pre-line ml-2'>{item[1]}</span>
      </span>
    ));

    const memoizedCallout = useMemo(
      () => (
        <Callout type='def' view='smooth' color='primary' icon='info-sign'>
          {!isWr
            ? 'Вы можете нажать на ключ он подставится в текст.'
            : 'Чтобы добавить форматирование, выделите текст и нажмите символ форматирования, например *текст*'}
        </Callout>
      ),
      [isWr],
    );

    return (
      <div className={className}>
        <div className='border border-quartz rounded-lg px-4 py-3 sticky top-[10px]'>
          <Icon variant='formatting' className='hidden mb-4 xl:block h-10 w-10' />
          <h3
            className={`relative flex justify-between font-bold text-blackText text-h6Mobile ${
              isOpen ? 'mb-2' : ''
            } ${forMobile ? 'pr-6' : ''} xl:mb-2 translate-all  ease-out duration-300 w-full`}>
            {!isWr ? 'Ключи и форматирование текста' : 'Форматирование текста'}
            <span
              onClick={onclickHandler}
              className='absolute top-0 right-0 bottom-0 left-0 flex items-center justify-end xl:hidden'>
              <Icon
                variant='caretDown'
                className={`${
                  isOpen ? 'rotate-180' : ''
                } translate-all  ease-out duration-300 h-[1rem] w-[1rem]`}
              />
            </span>
          </h3>
          <div
            className={`overflow-hidden max-h-0 ${
              isOpen ? 'max-h-max' : ''
            } xl:max-h-max translate-all  ease-out duration-300`}>
            {!isWr ? (
              <p className={`${addStyle} mb-1`}>
                Вы можете подставить в сообщение любую информацию, для этого вставьте в текст любой
                из ключей:
              </p>
            ) : null}
            {keysToRender.length ? (
              <p className='flex flex-col mb-5'>{keysToRender}</p>
            ) : (
              <p className='my-4'>Для этого типа задачи использование ключей не предусмотрено</p>
            )}
            <div className={addStyle}>{memoizedCallout}</div>
          </div>
        </div>
      </div>
    );
  },
);
