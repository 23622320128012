import React from 'react';
//
import { TKeyboardClickEvent } from '@shared/types';
import { PhoneNumberInput } from '@blocks/phoneNumberInput';

type TBlackListInputProps = {
  /**
   * Значение для элемента input
   * @param {string}
   */
  value: string;
  /**
   * Значение placeholder в input
   * @param {string}
   */
  placeholder: string;
  /**
   * Callback для обработки события change для элемента input
   * @param {(value: string) => void}
   */
  onChangeHandler: (value: string) => void;
  /**
   * Опциональный callback для добавления номер по нажатию на кнопку Enter
   * @param {TKeyboardClickEvent}
   */
  onKeyDownHandler?: TKeyboardClickEvent;
  /**
   * Список стран которые будут находится сверху списка
   * @param {string[]}
   */
  preferredCountries?: string[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const BlackListInput = ({
  value,
  placeholder,
  onChangeHandler,
  onKeyDownHandler,
  preferredCountries,
  className = '',
}: TBlackListInputProps) => (
  <PhoneNumberInput
    value={value}
    disableDropdown={false}
    placeholder={placeholder}
    onChangeHandler={onChangeHandler}
    onKeyDownHandler={onKeyDownHandler}
    preferredCountries={preferredCountries}
    className={className}
  />
);
