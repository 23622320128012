import React, { memo, useState } from 'react';
//
import { Chips } from '@uikit/Chips';
import { Button } from '@uikit/Button';
import { Table } from '@uikit/Table/Table';
import { RIGHT_MENU_ITEMS, RIGHT_MENU_ITEMS_OWNER } from '@const/settings';
import { RightDropdown } from '@components/rightDropdown';
import { LinkInTableWithCopyButton } from '@components/linkInTableWithCopyButton';
import { TChangeAdminStatusCallback, TSelectAdminRightHandler, TSortCallback } from '@shared/types';
import { EAdminState, TAdminsListItem, TAdminState } from '@models/Settings';
import { ACCESS_RIGHTS } from '@const/access';

type TSettingsAdminsTableProps = {
  /**
   * Данные для таблицы
   * @param {TAdminsListItem[]}
   */
  data: TAdminsListItem[];
  /**
   * Callback для сортировки
   * @param {TSortCallback}
   */
  sortCallback: TSortCallback;
  /**
   * Callback для изменения статуса
   * @param {TChangeAdminStatusCallback}
   */
  changeAdminStatusCallback: TChangeAdminStatusCallback;
  /**
   * Callback для обработки выбора прав админа в таблице
   * @param {TSelectAdminRightHandler}
   */
  setSelectedRightHandler: TSelectAdminRightHandler;
  /**
   * Флаг указывает на то, что авторизованный пользоятель является овнером
   * @param {boolean}
   */
  isOwner: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};
export const SettingsAdminsTable = memo(
  ({
    isOwner,
    sortCallback,
    setSelectedRightHandler,
    changeAdminStatusCallback,
    className = '',
    data,
  }: TSettingsAdminsTableProps) => {
    const [isHovered, setHovered] = useState<TAdminsListItem | null>(null);

    // Функция для рендера статуса
    const getCallStatusChips = (status: TAdminState) => {
      const isConnected = status === 'open';
      const isDisabled = status === 'close';

      return (
        <Chips
          round
          noClick
          type='dense'
          color={isConnected ? 'success' : isDisabled ? 'warning' : 'danger'}
          tag={isConnected ? 'Подключен' : isDisabled ? 'Заблокирован' : 'Не подключен'}
        />
      );
    };

    // Функция для рендера кнопки, при наведении на статус
    const getCellHoveredButton = ({ id, state }: TAdminsListItem) => {
      const isConnected = state === EAdminState.OPEN;
      const isDisabled = state === EAdminState.CLOSE;
      const color = isDisabled ? 'success' : 'danger';
      return (
        <Button
          fill
          dense
          color={color}
          view='outlined'
          onClick={changeAdminStatusCallback({
            id,
            state: isConnected ? EAdminState.CLOSE : EAdminState.OPEN,
          })}>
          {isConnected ? 'Заблокировать' : 'Разблокировать'}
        </Button>
      );
    };

    // Функция для рендера текста
    const getStylingText = (text: string) => (
      <span className='leading-6 tracking-[0.0275em]'>{text}</span>
    );

    const Head = [
      { id: 'name', label: 'Сотрудник' },
      { id: 'adminRights', label: 'Доступы' },
      { id: 'staffLink', label: 'Ссылка для подключения', noSort: true },
      { id: 'status', label: 'Статус', noSort: true },
    ];

    const rightDropdownData = isOwner
      ? { ...RIGHT_MENU_ITEMS, ...RIGHT_MENU_ITEMS_OWNER }
      : RIGHT_MENU_ITEMS;

    const getTableData = (tableData: TAdminsListItem[]) => {
      const emptyData = [
        {
          name: ' ',
          adminRights: ' ',
          connectionLink: ' ',
          status: ' ',
        },
      ];
      if (!tableData.length) {
        return emptyData;
      }

      return tableData.map(item => {
        const selectedRights = isOwner
          ? item.adminRights
          : item.adminRights.filter(right => right !== ACCESS_RIGHTS.SETTINGS_ADMINS);
        return {
          name: getStylingText(item.name),
          adminRights: (
            <RightDropdown
              adminName={item.name}
              selectedRights={selectedRights}
              rightToDropdown={rightDropdownData}
              selectAdminRightHandler={setSelectedRightHandler}
              className='min-w-[14.25rem] max-w-[14.25rem] w-full'
            />
          ),
          staffLink: (
            <LinkInTableWithCopyButton
              className='cursor-pointer hover:text-green1'
              text={item.adminLink}
            />
          ),
          status: (
            <div
              onMouseEnter={() => item.state !== 'not_activated' && setHovered(item)}
              onMouseLeave={() => setHovered(null)}
              className='w-[140px]'>
              {isHovered && isHovered.id === item.id
                ? getCellHoveredButton(item)
                : getCallStatusChips(item.state)}
            </div>
          ),
        };
      });
    };

    const test = getTableData(data);

    return (
      <div className={className}>
        <Table
          id='admins'
          name={Head}
          data={test}
          position={['left', 'left', 'left']}
          type='def'
          view='flat'
          color='default'
          checkboxes={false}
          paperClassName='no_shadow'
          onSortCallback={(collumName, order) => sortCallback(collumName, order)}
          style={{ boxShadow: 'none', marginLeft: '6px' }}
        />
      </div>
    );
  },
);
