import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Icon } from '@blueprintjs/core';
//
import { Button } from '@uikit/Button';
import isEqual from '@helpers/isEqual';
import { Loader } from '@blocks/loader';
import { Overlay } from '@atoms/overlay';
import { TOOLTIPS_TEXT } from '@data/dict';
import { getAppData } from '@redux/selectors';
import { AppDrawer } from '@components/appDrawer';
import { TAdminsListItem } from '@models/Settings';
import { ErrorMessage } from '@blocks/errorMessage';
import { TPageView } from '@helpers/myTracker/type';
import { ToastsMessages } from '@components/toastsMessages';
import { clickOnElement, getEventData } from '@helpers/index';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { NoteSuccessModal } from '@components/noteSuccessModal';
import { AdminSettingsDrawer } from '@blocks/adminSettingsDrawer';
import { SettingsAdminsTable } from '@components/settingsAdminsTable';
import { setAppDrawerData, setShowAppDrawer } from '@redux/App/slice';
import { getAdminsTableData, saveAdminsTableData } from '@redux/Settings/thunk';
import { MY_TRACKER_EVENTS, PAGE_NAMES, postEvent } from '@helpers/myTracker/myTracker';
import {
  TSortCallback,
  TOnOpenDrawerHandler,
  TSelectAdminRightHandler,
  TChangeAdminStatusCallback,
} from '@shared/types';
import {
  changeAdminStatus,
  setSelectedRights,
  setAdminTableOldData,
  setSortTypeDataSPAdmins,
  clearAdminsTableStatuses,
  setIsShowNoSaveExitModal,
} from '@redux/Settings/slice';

type TSettingsAdminProps = {
  /**
   * Флаг повторного запрашивания данных страницы
   * @param {boolean}
   */
  isRetries: boolean;
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Данные для таблицы админов
   * @param {TAdminsListItem[]}
   */
  adminsTableData: TAdminsListItem[];
  /**
   * Данные для таблицы админов на момент открытия страницы
   * @param {TAdminsListItem[]}
   */
  adminsTableOldData: TAdminsListItem[];
  /**
   * Флаг ошибки при получении данных
   * @param {boolean}
   */
  isAdminsTableDataError: boolean;
  /**
   * Флаг состояния процесса получения данных
   * @param {boolean}
   */
  isAdminsTableDataLoading: boolean;
  /**
   * Флаг успешного получения данных для таблицы
   * @param {boolean}
   */
  isAdminsTableDataSuccess: boolean;
  /**
   * Флаг состояния процесса сохранения данных
   * @param {boolean}
   */
  isAdminsTableDataSaving: boolean;
  /**
   * Текст сообщения об ошибке
   * @param {string}
   */
  errorToastMessage: string;
  /**
   * Текст сообщения об успешной операции
   * @param {string}
   */
  successToastMessage: string;
  /**
   * Флаг указывает на то, что авторизованный пользоятель является овнером
   * @param {boolean}
   */
  isOwner: boolean;
  /**
   * Флаг отображения модпьного окна с предепреждением о не сохраненнх данных на странице
   * @param {boolean}}
   */
  isShowNoSaveExitModal: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const SettingsAdmin = memo(
  ({
    isOwner,
    isRetries,
    adminsTableData,
    errorToastMessage,
    adminsTableOldData,
    successToastMessage,
    selectedFilialAccId,
    isShowNoSaveExitModal,
    isAdminsTableDataError,
    isAdminsTableDataSaving,
    isAdminsTableDataLoading,
    isAdminsTableDataSuccess,
    className = '',
  }: TSettingsAdminProps) => {
    const ref = useRef<(EventTarget & Element) | undefined>(undefined);
    const [clickCoordinates, setClickCoordinates] = useState({
      clientX: 0,
      clientY: 0,
    });
    const dispatch = useAppDispatch();
    const { showAppDrawer } = useAppSelector(getAppData);

    useEffect(() => {
      postEvent<TPageView>(
        getEventData<TPageView>({
          eventName: MY_TRACKER_EVENTS.LK_PAGE_VIEW,
          eventProperties: { name_page: PAGE_NAMES.SETTINGS_ADMINS },
        }),
      );
    }, []);

    // Запрашивает данные страницы умного расписания и админов повторно при ошибке токена
    useEffect(() => {
      if (isRetries) {
        setTimeout(() => {
          dispatch(getAdminsTableData());
        }, 100);
      }
    }, [dispatch, isRetries]);

    // Запрашивает данные списка админов при первом рендеренге и при смене филиала в меню
    useEffect(() => {
      if (selectedFilialAccId) {
        dispatch(getAdminsTableData());
      }
    }, [dispatch, selectedFilialAccId]);

    // функция сортировки, получает название столбца отправляет в редакс
    const setSortTypeHandler: TSortCallback = (collumName, order) => {
      dispatch(setSortTypeDataSPAdmins({ collumName, order }));
    };

    // функция изменяет статус админа и запрашиваеданные таблицы заново
    const changeAdminStatusHandler: TChangeAdminStatusCallback = data => () => {
      dispatch(changeAdminStatus(data));
    };

    // Обрабатывает выбор прав админа в таблице
    const setSelectedRightHandler: TSelectAdminRightHandler = (selectedRight, name) => event => {
      const { checked } = event.currentTarget;
      dispatch(setSelectedRights({ checked, name, selectedRight }));
    };

    // Скрывает сообщения об ошибках и успехах в запросах
    const clearAllToastMessages = () => {
      dispatch(clearAdminsTableStatuses());
    };

    const onCloseDrawerHandler = () => {
      dispatch(setShowAppDrawer(false));
      dispatch(setAppDrawerData({ title: '', description: '' }));
    };

    const onOpenDrawerHandler: TOnOpenDrawerHandler = useCallback(
      ({ title, description }) =>
        () => {
          dispatch(setShowAppDrawer(true));
          dispatch(setAppDrawerData({ title, description }));
        },
      [dispatch],
    );

    const isSettingsChanged = useMemo(
      () => !isEqual(adminsTableData, adminsTableOldData),
      [adminsTableData, adminsTableOldData],
    );

    const closeSaveExitModal = useCallback(() => {
      dispatch(setIsShowNoSaveExitModal(false));
      dispatch(setAdminTableOldData(adminsTableData));

      setTimeout(() => {
        clickOnElement({
          x: clickCoordinates.clientX,
          y: clickCoordinates.clientY,
          overlayElement: ref.current,
        });
      }, 150);
    }, [clickCoordinates, adminsTableData, dispatch]);

    const closeElementCallback = useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
        if (isSettingsChanged && event) {
          setClickCoordinates({
            clientX: event.clientX,
            clientY: event.clientY,
          });

          ref.current = event.currentTarget;

          dispatch(setIsShowNoSaveExitModal(true));
        }
      },
      [isSettingsChanged, dispatch],
    );

    // функция сохраняет изменения на странице
    const postSettingHandler = () => {
      dispatch(
        saveAdminsTableData({ tableData: adminsTableData, successCallback: closeSaveExitModal }),
      );
    };

    return (
      <div className={className}>
        {isSettingsChanged && !isAdminsTableDataError && !isAdminsTableDataLoading
          ? createPortal(
              <Overlay onClickCallback={closeElementCallback} className='!z-[1]' />,
              document.body,
            )
          : null}
        {!isAdminsTableDataError ? (
          <div className='relative !z-[2]'>
            <div className='flex items-center mb-2'>
              <h2 className='text-h3Mobile text-black font-medium tracking-[0.004em] leading-8'>
                Админы
              </h2>
              <div className='flex items-center justify-center cursor-pointer w-10'>
                <Icon
                  icon='info-sign'
                  color='#37833B'
                  onClick={onOpenDrawerHandler({
                    title: 'Админы',
                    description: TOOLTIPS_TEXT.admins,
                  })}
                />
              </div>
            </div>
            {isAdminsTableDataSuccess && !isAdminsTableDataLoading ? (
              <SettingsAdminsTable
                isOwner={isOwner}
                sortCallback={setSortTypeHandler}
                setSelectedRightHandler={setSelectedRightHandler}
                changeAdminStatusCallback={changeAdminStatusHandler}
                data={adminsTableData}
                className='w-screen lg:w-full overflow-x-auto dropdown-list-scrollbar pb-4'
              />
            ) : (
              <Loader className='h-[50Vh]' />
            )}
            <Button
              dense
              type='action'
              view='filled'
              color='success'
              text='Сохранить настройки'
              onClick={postSettingHandler}
              loading={isAdminsTableDataSaving}
              className={`relative mt-6 w-fit ${isSettingsChanged ? '!z-[2]' : ''}`}
            />
          </div>
        ) : (
          <ErrorMessage />
        )}
        <ToastsMessages
          errorMessage={errorToastMessage}
          successMessage={successToastMessage}
          isShowErrorToast={!!errorToastMessage}
          isShowSuccessToast={!!successToastMessage}
          clearErrorCallback={clearAllToastMessages}
          clearSuccessStatusCallback={clearAllToastMessages}
        />
        <AppDrawer
          showAppDrawer={showAppDrawer}
          onCloseHandler={onCloseDrawerHandler}
          displayElement={<AdminSettingsDrawer />}
        />
        {isShowNoSaveExitModal ? (
          <NoteSuccessModal
            isOpen={isShowNoSaveExitModal}
            isLoading={isAdminsTableDataLoading}
            successButtonText='Сохранить настройки'
            cancelButtonText='Выйти без сохранения'
            title='Сохраните настройки перед выходом'
            description='Вы пытаетесь выйти без сохранения настроек.'
            successButtonCallback={postSettingHandler}
            cancelButtonCallback={closeSaveExitModal}
          />
        ) : null}
      </div>
    );
  },
);
