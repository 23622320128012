import React, { memo } from 'react';
import RichTextExample from '@components/slateEditor/richTextEditor';
import { TSimpleStringObj } from '@models/index';
import { replaceKeys, slateEditorStringParser } from '@helpers/index';

type TProps = {
  /**
   * Объект ключей для проверки ключей на валидность
   * @param {TSimpleStringObj}
   */
  keys: TSimpleStringObj;
  /**
   * Описание задачи
   * @param {string}
   */
  description: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};
export const TaskPreviewComponent = memo(({ description, keys, className = '' }: TProps) => {
  const newValue = () => {
    try {
      const v = JSON.parse(description);
      if (Array.isArray(v)) {
        return replaceKeys(v, keys);
      }
      return slateEditorStringParser(description, keys);
    } catch (e) {
      return slateEditorStringParser(description, keys);
    }
  };

  return (
    <div className={className}>
      <RichTextExample
        readOnly
        keys={{}}
        editorClass=''
        value={newValue()}
        isCheckKeys={false}
        onChange={() => ''}
      />
    </div>
  );
});

TaskPreviewComponent.displayName = 'TaskPreviewComponent';
