import React, { useCallback, useEffect, useState } from 'react';
//
import { log } from '@helpers/index';
import { TExecutor } from '@models/Tasks';
import { useAppDispatch } from '@hooks/index';
import { TSimpleStringObjLevel2 } from '@models/index';
import { TGetCategoryListHandler } from '@shared/types';
import { getAdminsTableData } from '@redux/Settings/thunk';
import { DropdownWithIcon } from '@components/dropdownWithIcon';
import { InputWithDropdownFromEP } from '@components/inputWithDropdownFromEP';
import {
  performerSetRule,
  performersSetOrder,
  TASK_DATA_KEY_NAMES,
  PERFORMER_SET_ORDER,
  performerSecondSetRule,
  PERFORMER_ASSIGMENT_RULE,
} from '@const/task';

type TTaskPerformerProps = {
  /**
   * ID выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Объект с данными сотрудников и услуг
   * @param {TSimpleStringObjLevel2}
   */
  categoriesDataList: TSimpleStringObjLevel2;
  /**
   * Флаг успешного получения данных страницы настроек
   * @param {boolean}
   */
  isSuccess: boolean;
  /**
   * Поле альтернативного исполнителя для инпута
   * @type {TExecutor | null}
   */
  alternativeExecutor: TExecutor | null;
  /**
   * Параметр определяющий правило прикрепления задачи
   * @type {PERFORMER_ASSIGMENT_RULE | null}
   */
  assignmentRule: PERFORMER_ASSIGMENT_RULE | null;
  /**
   * Альтернативный параметр определяющий правило прикрепления задачи
   * @type {PERFORMER_ASSIGMENT_RULE | null}
   */
  backupRule: PERFORMER_ASSIGMENT_RULE | null;
  /**
   * Приоритетны исполнитель
   * @type {TExecutor | null}
   */
  priorityExecutor: TExecutor | null;
  /**
   * Колбэк для установки приоритетного исполнителя
   * @type {(id: number) => void}
   * @param id
   */
  setPriorityExecutor: (id: number) => void;
  /**
   * Колбэк для установки правила прикрепления задачи к исполнителю
   * @type {(value: PERFORMER_ASSIGMENT_RULE) => void}
   * @param value
   */
  setAssigmentRule: (value: PERFORMER_ASSIGMENT_RULE) => void;
  /**
   * Колбэк для установки альтернативного исполнителя
   * @type {(id: number) => void}
   * @param id
   */
  setAlternativeExecutor: (id: number) => void;
  /**
   * Колбэк для установки правила прикрепления задачи к альтернативному исполнителю
   * @type {(value: PERFORMER_ASSIGMENT_RULE) => void}
   * @param value
   */
  setBackupRule: (value: PERFORMER_ASSIGMENT_RULE) => void;
  /**
   * Колбэк очистки полей прикрепления задачи к исполнителю
   * @type {() => void}
   */
  clearTaskExecutors: () => void;
};
export const TaskPerformer = ({
  isSuccess,
  backupRule,
  setBackupRule,
  assignmentRule,
  setAssigmentRule,
  priorityExecutor,
  clearTaskExecutors,
  categoriesDataList,
  selectedFilialAccId,
  setPriorityExecutor,
  alternativeExecutor,
  setAlternativeExecutor,
}: TTaskPerformerProps) => {
  const dispatch = useAppDispatch();
  const [performerSetOrder, setPerformerSetOrder] = useState<boolean>(false);

  useEffect(() => {
    setPerformerSetOrder(Boolean(priorityExecutor));
  }, [priorityExecutor]);

  const performerSetOrderSwitcher = () => () => {
    if (performerSetOrder) {
      clearTaskExecutors();
    }
    setPerformerSetOrder(prevState => !prevState);
  };

  const getStaffList: TGetCategoryListHandler = () => {
    dispatch(getAdminsTableData());
  };

  const setPriorityExecutorHandler = (_: string, value: string) => {
    setPriorityExecutor(Number(value));
  };

  const setAlternativeExecutorHandler = (_: string, value: string) => {
    setAlternativeExecutor(Number(value));
  };

  const setAssigmentRuleHandler = (value: string) => () => {
    setAssigmentRule(value as PERFORMER_ASSIGMENT_RULE);
  };

  const setBackupRuleHandler = (value: string) => () => {
    setBackupRule(value as PERFORMER_ASSIGMENT_RULE);
  };

  const deleteBoonHandler = useCallback(
    (number: string, name: string) => () => log(number, name),
    [],
  );

  return (
    <div>
      <h2 className='text-blackText text-h4 font-medium mb-4'>Исполнитель</h2>
      <DropdownWithIcon
        items={performersSetOrder}
        selectedItem={!performerSetOrder ? PERFORMER_SET_ORDER.FIRST : PERFORMER_SET_ORDER.PRIORITY}
        callBack={performerSetOrderSwitcher}
        label='На кого назначить задачу'
        className='mb-4'
      />
      {performerSetOrder ? (
        <>
          <span className='text-body1Mobile text-grayText'>Приоритетный исполнитель</span>
          <InputWithDropdownFromEP
            disabled
            addedValues={[]}
            newValue={priorityExecutor ? String(priorityExecutor.user_id) : ''}
            isFetchingSuccess={isSuccess}
            deleteBalloon={deleteBoonHandler}
            categoriesDataList={categoriesDataList}
            getCategoryListHandler={getStaffList}
            selectedFilialAccId={selectedFilialAccId}
            addBalloonOnBlur={setPriorityExecutorHandler}
            inputId={TASK_DATA_KEY_NAMES.STAFF}
            isSoloValue
            hideFirstDropdown
            labelTextVariant={TASK_DATA_KEY_NAMES.STAFF}
            className='max-w-[47rem] mb-4'
          />
        </>
      ) : null}
      {priorityExecutor ? (
        <DropdownWithIcon
          items={performerSetRule}
          selectedItem={assignmentRule || PERFORMER_ASSIGMENT_RULE.FIRST_ADMIN}
          callBack={setAssigmentRuleHandler}
          label='Если исполнитель не на смене'
          className='mb-4'
        />
      ) : null}
      {assignmentRule === PERFORMER_ASSIGMENT_RULE.ALTERNATIVE_EXECUTOR ? (
        <>
          <span className='text-body1Mobile text-grayText'>Альтернативный исполнитель</span>
          <InputWithDropdownFromEP
            disabled
            isDanger={!alternativeExecutor}
            addedValues={[]}
            newValue={alternativeExecutor ? String(alternativeExecutor.user_id) : ''}
            isFetchingSuccess={isSuccess}
            deleteBalloon={deleteBoonHandler}
            categoriesDataList={categoriesDataList}
            getCategoryListHandler={getStaffList}
            selectedFilialAccId={selectedFilialAccId}
            addBalloonOnBlur={setAlternativeExecutorHandler}
            inputId={TASK_DATA_KEY_NAMES.STAFF}
            isSoloValue
            hideFirstDropdown
            labelTextVariant={TASK_DATA_KEY_NAMES.STAFF}
            className='max-w-[47rem] mb-4'
          />
        </>
      ) : null}
      {alternativeExecutor && assignmentRule === PERFORMER_ASSIGMENT_RULE.ALTERNATIVE_EXECUTOR ? (
        <DropdownWithIcon
          items={performerSecondSetRule}
          selectedItem={backupRule || PERFORMER_ASSIGMENT_RULE.FIRST_ADMIN}
          callBack={setBackupRuleHandler}
          label='Если альтернативный исполнитель не на смене'
        />
      ) : null}
    </div>
  );
};
