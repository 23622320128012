import { getEventData, log } from '@helpers/index';
import { payApi } from '@api/pay/payApi';
import { TRejectValue } from '@models/index';
import { payMappers } from '@redux/Pay/mappers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { TPageEdit, TPageView } from '@helpers/myTracker/type';
import { PAY_RESPONSE_KEY, REJECT_RESPONSE_KEY } from '@api/types';
import { TPayData, TPayerData, TPayLinkData, PayResponseDataSchema } from '@models/Pay';
import {
  postEvent,
  PAGE_NAMES,
  MY_TRACKER_EVENTS,
  PAGE_CHANGES_TYPE,
} from '@helpers/myTracker/myTracker';

export const getPayPageData = createAsyncThunk<TPayData | void, void, TRejectValue>(
  'pay/getPayPageData',
  (_, { rejectWithValue }) => {
    return payApi
      .getPayData()
      .then(response => {
        if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error(String(response.data.status));
        }
        if (PAY_RESPONSE_KEY.BRANCH in response.data) {
          const parsedData = PayResponseDataSchema.safeParse(response.data);

          if (!parsedData.success) {
            log('@@pay/getPayPageData pars error', parsedData.error.format());

            // throw new Error(parsedData.error.toString());
          }
          return payMappers.responseToStore(response.data);
        }
      })
      .catch(error => rejectWithValue(error.response.data));
  },
);

export const getPayerDataThunk = createAsyncThunk<TPayerData | void, void, TRejectValue>(
  'pay/getPayerData',
  (_, { rejectWithValue, dispatch }) => {
    return payApi
      .getPayerData()
      .then(response => {
        if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error(String(response.data.status));
        }
        if (PAY_RESPONSE_KEY.INN in response.data) {
          return payMappers.payerDataResponseToStore(response.data);
        }
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(getPayerDataThunk()), 200);
        } else {
          rejectWithValue(error.response.data);
        }
      });
  },
);

export const setPayerDataThunk = createAsyncThunk<
  void,
  { payerData: TPayerData; successCallback?: () => void },
  TRejectValue
>('pay/setPayerData', (data, { rejectWithValue, dispatch }) => {
  return payApi
    .setPayerData(payMappers.payerStoreDataToRequest(data.payerData))
    .then(() => {
      postEvent<TPageView & TPageEdit>(
        getEventData<TPageView & TPageEdit>({
          eventName: MY_TRACKER_EVENTS.LK_PAGE_CHANGE,
          eventProperties: {
            name_page: PAGE_NAMES.SETTINGS_BILL_DATA,
            type_changes: PAGE_CHANGES_TYPE.STRUCTURE,
          },
        }),
      );

      if (data.successCallback) data.successCallback();
    })
    .catch(error => {
      if (error?.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(setPayerDataThunk(data)), 200);
      } else {
        rejectWithValue(error?.response?.data);
      }
    });
});

export const getPayLinkThunk = createAsyncThunk<
  TPayLinkData | void,
  {
    onSuccess: (data: TPayLinkData) => void;
    months: number;
  },
  TRejectValue
>('pay/getPayLink', (data, { rejectWithValue }) => {
  return payApi
    .getPayLink(data.months)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (PAY_RESPONSE_KEY.LINK in response.data) {
        const linkData = payMappers.payLinkResponseToStore(response.data);

        data.onSuccess(linkData);

        return linkData;
      }
    })
    .catch(error => rejectWithValue(error.response.data));
});

export const getInvoiceThunk = createAsyncThunk<
  TPayLinkData | void,
  {
    months: number;
    pay_all: boolean;
    in_telegram: boolean;
  } & {
    onSuccess: (data: TPayLinkData) => void;
  },
  TRejectValue
>('pay/getInvoice', (data, { rejectWithValue }) => {
  return payApi
    .getInvoiceLink(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (PAY_RESPONSE_KEY.LINK in response.data) {
        const linkData = payMappers.payLinkResponseToStore(response.data);
        data.onSuccess(linkData);
        return linkData;
      }
    })
    .catch(error => rejectWithValue(error.response.data));
});
