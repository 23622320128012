export const numberOfMonth = ['3', '6', '12'];
export const numberOfGiftWeek = ['1', '3', '8'];

export enum PAY_PAGE_SUCCESS_MESSAGES {
  SEND_INVOICE_IN_TG = 'Счет отправлен в telegram ',
  SUCCESS_SAVED = 'Данные сохранены',
}

export enum PAY_PAGE_ERROR_MESSAGES {
  SEND_INVOICE_IN_TG = 'Счет не отправлен в telegram ',
  INN_ERROR = 'У Вас не заполнены платежные данные. Чтобы заполнить - перейдите в настройки.',
  SAVE_ERROR = 'Ошибка сохранения данных',
  GET_INVOICE_ERROR = 'Ошибка получения счета',
  GET_LINK_ERROR = 'Ошибка получения ссылки',
}

export enum PAY_TAGS {
  BILL = 'bill',
  LINK_PAID = 'link_paid',
  AUTOPAY = 'autopay',
  BONUS = 'bonus',
  FOR_PARTNER = 'for_partner',
}

export const BANNER_LINK = 'https://yclients.com/m/mp_3_beautybot_review/';

export const MONTH_WORDS = ['месяц', 'месяца', 'месяцев'];

export const WEEK_WORDS = ['неделя', 'недели', 'недель'];

export const PAY_PAGE_TEXT = {
  header: 'Оплата',
  payStatusTitle: 'статус оплаты',
  payTill: 'Оплачен до',
  notPaid: 'Не оплачен',
  payEvery: 'Оплата каждое',
  deyOfMonth: 'число месяца',
  filial: 'Филиал:',
  yourTariff: 'Ваш тариф:',
  employers: 'сотрудников',
  rubl: 'руб.',
  month: 'в месяц',
  autoPayment: 'Автоплатеж',
  autoPaymentEnabled: 'подключен',
  autoPaymentDisabled: 'не подключен',
  card: 'Карта',
  enableAutoPayment: 'Подключите автоплатёж',
  changeCard: 'Изменить карту',
  rublSign: '₽',
  monthShort: 'мес.',
  amount: 'итого',
  withAutoPayments: 'с автоплатежом',
  promoCodeLabel: 'Введите промокод',
  promoCodePlaceholder: 'Промокод',
  setPromCode: 'Применить',
  enableAutomaticPayment: 'Вы хотите подключить автоплатеж',
  paymentDescription: 'Вы будете перенаправлены на сайт платёжной системы, слейдуйте инструкциям.',
  refresh: 'Обновить',
  cancel: 'Отмена',
  connect: 'Подключить',
  changeAutomaticPaymentCardTitle: 'Вы желаете изменить карту для автоплатежа',
  changeAutomaticPaymentCard: 'Вы желаете изменить карту для филиала:',
  changeAutomaticPaymentCardDescription:
    'В окне кассы укажите карту и выполните платёж. После завершения оплаты, пожалуйста, обновите страницу.',
  change: 'Изменить',
  disableAutomaticPaymentTitle: 'Вы хотите отключить автоплатеж',
  disable: 'Отключить',
  automaticPaymentDisabledTitle: 'Автоплатёж успешно отключен.',
  pleaseRefreshPage: 'Пожалуйста обновите страницу.',
  billCreated: 'Счет сформирован',
  billCreated2: 'Счет сформирован за',
  sendInTg: 'Отправить в TG',
  downloadPdf: 'Скачать .PDF',
  billSum: 'на сумму',
  downloadBill: 'Скачать счет',
  pay: 'Оплатить',
  cardPay: 'по карте',
  linkPay: 'по ссылке',
  commercialHeader: '2 недели в подарок',
  commercialDescription: 'Оставьте отзыв в маркетплейсе YCLIENTS и получите',
  commercialGift: '2 недели в подарок!',
  commercialButton: 'Написать отзыв',
};

export const MIN_NAME_LENGTH = 3;
export const MIN_INN_LENGTH = 10;
export const MAX_INN_LENGTH = 12;
export const KPP_LENGTH = 9;
