import React, {
  memo,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { withHistory } from 'slate-history';
import { Editable, withReact, useSlate, Slate } from 'slate-react';
import {
  Node,
  Point,
  Range,
  Editor,
  Transforms,
  Descendant,
  createEditor,
  Element as SlateElement,
} from 'slate';
import { TChildRef } from '@shared/types';
import { TSimpleStringObj } from '@models/index';
import {
  addKey,
  withInlines,
  KeyComponent,
  isLinkActive,
  LinkComponent,
  AddLinkToggle,
  TextComponent,
  BadgeComponent,
  EditableButtonComponent,
} from '@components/slateEditor/inlines';

import { InsertImageButton, withImages, Image } from './image';
import { Button, CheckListItemElement, Icon, Toolbar } from './components';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

// eslint-disable-next-line
// @ts-ignore
export const serializeStringNodes = nodes => {
  let result;
  try {
    result = JSON.parse(nodes)
      // eslint-disable-next-line
      // @ts-ignore
      .map(n => Node.string(n))
      .join('\n');
  } catch (e) {
    result = nodes;
  }
  return result;
};
// eslint-disable-next-line
// @ts-ignore
export const serialize = nodes => {
  let result;
  try {
    result = nodes
      // eslint-disable-next-line
      // @ts-ignore
      .map(n => {
        return Node.string(n);
      })
      .join('\n');
  } catch (e) {
    result = nodes;
  }
  return result;
};

const isBlockActive = (editor: Editor, format: string, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        // eslint-disable-next-line
        // @ts-ignore
        n[blockType] === format,
    }),
  );

  return !!match;
};

const toggleBlock = (editor: Editor, format: string) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type',
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      // eslint-disable-next-line
      // @ts-ignore
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties: Partial<SlateElement>;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      // eslint-disable-next-line
      // @ts-ignore
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      // eslint-disable-next-line
      // @ts-ignore
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const isMarkActive = (editor: Editor, format: string) => {
  const marks = Editor.marks(editor);
  // eslint-disable-next-line
  // @ts-ignore
  return marks ? marks[format] === true : false;
};

const toggleMark = (editor: Editor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

// eslint-disable-next-line
// @ts-ignore
const Element = props => {
  const { attributes, children, element } = props;

  const style = { textAlign: element.align };
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case 'check-list-item':
      return <CheckListItemElement {...props} />;
    case 'image':
      return <Image {...props} />;
    case 'link':
      return <LinkComponent {...props} />;
    case 'button':
      return <EditableButtonComponent {...props} />;
    case 'badge':
      return <BadgeComponent {...props} />;
    case 'key':
      return <KeyComponent {...props} />;
    case 'text':
      return <TextComponent {...props} />;

    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

// eslint-disable-next-line
// @ts-ignore
const Leaf = memo(({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
});

type TButtonProps = {
  format: string;
  icon: string;
  title: string;
};

const BlockButton = memo(({ format, icon, title }: TButtonProps) => {
  const editor = useSlate();
  const reversed = false;
  const active = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type',
  );

  const onMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    toggleBlock(editor, format);
  };

  return (
    <Button
      title={title}
      active={active}
      reversed={reversed}
      onMouseDown={onMouseDown}
      className=''>
      <Icon title={title} active={active} className=''>
        {icon}
      </Icon>
    </Button>
  );
});

const MarkButton = ({ format, icon, title }: TButtonProps) => {
  const editor = useSlate();
  const reversed = false;
  const active = isMarkActive(editor, format);

  // eslint-disable-next-line
  const onMouseDown =(event: any) => {
    event.preventDefault();
    toggleMark(editor, format);
  };

  return (
    <Button
      className=''
      title={title}
      active={active}
      reversed={reversed}
      onMouseDown={onMouseDown}>
      <Icon title={title} active={active} className=''>
        {icon}
      </Icon>
    </Button>
  );
};

export const withChecklists = (editor: Editor) => {
  const { deleteBackward } = editor;

  // eslint-disable-next-line
  // @ts-ignore
  editor.deleteBackward = (...args) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      // eslint-disable-next-line
      // @ts-ignore
      const [match] = Editor.nodes(editor, {
        // eslint-disable-next-line
        // @ts-ignore
        match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          // eslint-disable-next-line
          // @ts-ignore
          n.type === 'check-list-item',
      });

      if (match) {
        const [, path] = match;
        // eslint-disable-next-line
        // @ts-ignore
        const start = Editor.start(editor, path);
        if (Point.equals(selection.anchor, start)) {
          const newProperties: Partial<SlateElement> = {
            // eslint-disable-next-line
            // @ts-ignore
            type: 'paragraph',
          };
          Transforms.setNodes(editor, newProperties, {
            match: n =>
              // eslint-disable-next-line
              // @ts-ignore
              !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'check-list-item',
          });
          return;
        }
      }
    }

    deleteBackward(...args);
  };

  return editor;
};

type TProps = {
  value: Descendant[];
  readOnly: boolean;
  keys?: TSimpleStringObj;
  isCheckKeys: boolean;
  // eslint-disable-next-line
  onFocus?: any;
  // eslint-disable-next-line
  onKeyUp?: any;
  onChange?: (newValue: Descendant[]) => void;
  editorClass?: string;
  className?: string;
};

const RichTextEditor = forwardRef<TChildRef, TProps>(
  (
    { isCheckKeys, keys, value, onChange, editorClass, onFocus, onKeyUp, readOnly, className = '' },
    ref,
  ) => {
    const addLinkRef = useRef<Record<string, () => void>>({});
    // eslint-disable-next-line
    const renderElement = useCallback((props: any) => <Element {...props} />, []);
    // eslint-disable-next-line
    const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);

    // eslint-disable-next-line
  const editor: any = useMemo(
      () => withHistory(withInlines(withImages(withChecklists(withReact(createEditor()))))),
      // eslint-disable-next-line
      [],
    );

    // Хука для обновления параметров инлайнов, если это возможно.
    useEffect(() => {
      // Обновление логики в `withInlines` без пересоздания редактора.
      editor.isCheckKeys = isCheckKeys;
      editor.keys = keys;
      // Предполагается, что функция `withInlines` корректно обновляет свое состояние
      // при изменении `isCheckKeys` и `keys`.
    }, [isCheckKeys, keys, editor]);

    const onDoubleClickHandler = useCallback(() => {
      if (isLinkActive(editor)) {
        if (addLinkRef.current.enableEdit) {
          addLinkRef.current.enableEdit();
        }
      }
    }, [editor]);

    useImperativeHandle(
      ref,
      () => {
        return {
          setKey(key: string) {
            addKey(editor, key);
          },
          setEmoji(emoji: string) {
            const { insertText } = editor;
            insertText(emoji);
          },
        };
      },
      [editor],
    );

    const onKeyDownHandler = useCallback(
      (event: React.KeyboardEvent) => {
        const { key } = event;
        if ((key === '{' || key === '}') && isCheckKeys) {
          event.preventDefault();
        }
      },
      [isCheckKeys],
    );

    return (
      <div className={`flex flex-col grow ${className}`}>
        <Slate editor={editor} initialValue={value} onChange={onChange}>
          {!readOnly ? (
            <Toolbar className='bg-white flex flex-wrap sticky top-0 z-[20]'>
              <BlockButton format='heading-one' icon='looks_one' title='Заголовок первого уровня' />
              <BlockButton format='heading-two' icon='looks_two' title='Заголовок второго уровня' />
              <MarkButton format='bold' icon='format_bold' title='Шрифт жирный' />
              <MarkButton format='italic' icon='format_italic' title='Шрифт курсив' />
              <MarkButton format='underline' icon='format_underlined' title='Шрифт подчеркнутый' />
              <MarkButton format='code' icon='code' title='Блок кода' />
              <BlockButton format='block-quote' icon='format_quote' title='Цитата' />
              <BlockButton
                format='numbered-list'
                icon='format_list_numbered'
                title='Нумерованный список'
              />
              <BlockButton format='bulleted-list' icon='format_list_bulleted' title='Список' />
              <BlockButton format='check-list-item' icon='checklist' title='Чек-лист' />
              <BlockButton format='left' icon='format_align_left' title='Выровнять влево' />
              <BlockButton format='center' icon='format_align_center' title='Выровнять по центру' />
              <BlockButton format='right' icon='format_align_right' title='Выровнять вправо' />
              <BlockButton
                format='justify'
                icon='format_align_justify'
                title='Выровнять по ширине'
              />
              <InsertImageButton />
              <AddLinkToggle ref={addLinkRef} />
            </Toolbar>
          ) : null}
          <Editable
            autoFocus
            spellCheck
            onFocus={onFocus}
            onKeyUp={onKeyUp}
            readOnly={readOnly}
            renderLeaf={renderLeaf}
            onKeyDown={onKeyDownHandler}
            renderElement={renderElement}
            onDoubleClick={onDoubleClickHandler}
            className={`rich_text_editable min-h-[51vh] ${editorClass}`}
          />
        </Slate>
      </div>
    );
  },
);

export default RichTextEditor;
