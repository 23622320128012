import { createPortal } from 'react-dom';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
//
import isEqual from '@helpers/isEqual';
import { Button } from '@uikit/Button';
import { Overlay } from '@atoms/overlay';
import { THelpKeys } from '@models/index';
import { getSettingsData } from '@redux/selectors';
import { TPageView } from '@helpers/myTracker/type';
import { ToastsMessages } from '@components/toastsMessages';
import { SettingsCheckbox } from '@blocks/settingsCheckbox';
import { defaultNotificationSettings } from '@data/settings';
import { NOTIFICATIONS_VARIANTS_TEXT } from '@const/settings';
import { clickOnElement, getEventData } from '@helpers/index';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { IconWithTooltips } from '@components/iconWithTooltips';
import { NoteSuccessModal } from '@components/noteSuccessModal';
import { getNotificationsThunk, saveNotificationsThunk } from '@redux/Settings/thunk';
import { MY_TRACKER_EVENTS, PAGE_NAMES, postEvent } from '@helpers/myTracker/myTracker';
import { TNotificationItem, TSettingsNotificationResponseData } from '@models/Settings';
import {
  setSettingsNotification,
  clearNotificationsStatus,
  setIsShowNoSaveExitModal,
  setOldNotificationSettings,
} from '@redux/Settings/slice';

export const SettingsNotifications = () => {
  const ref = useRef<(EventTarget & Element) | undefined>(undefined);
  const [clickCoordinates, setClickCoordinates] = useState({
    clientX: 0,
    clientY: 0,
  });
  const dispatch = useAppDispatch();
  const {
    notificationsSettings,
    oldNotificationsSettings,
    isLoading,
    isErrorNotificationSave,
    isSuccessNotificationSave,
    isShowNoSaveExitModal,
  } = useAppSelector(getSettingsData);

  useEffect(() => {
    postEvent<TPageView>(
      getEventData<TPageView>({
        eventName: MY_TRACKER_EVENTS.LK_PAGE_VIEW,
        eventProperties: { name_page: PAGE_NAMES.SETTINGS_NOTIFICATION },
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(getNotificationsThunk());
  }, [dispatch]);

  const onCheckBoxChangeHandler = useCallback(
    (key: keyof TSettingsNotificationResponseData) => {
      return ({ target: { checked, name } }: React.ChangeEvent<HTMLInputElement>) => {
        const newNotificationData: TSettingsNotificationResponseData = notificationsSettings
          ? {
              ...notificationsSettings,
              [key]: {
                ...notificationsSettings[key],
                [name]: checked,
              },
            }
          : {
              ...defaultNotificationSettings,
              [key]: {
                ...defaultNotificationSettings[key],
                [name]: checked,
              },
            };
        dispatch(setSettingsNotification(newNotificationData));
      };
    },
    [dispatch, notificationsSettings],
  );

  const onHideToastHandler = useCallback(() => dispatch(clearNotificationsStatus()), [dispatch]);

  const renderList = useMemo(() => {
    return Object.keys(NOTIFICATIONS_VARIANTS_TEXT).map(key => {
      return (
        <div className='grid grid-cols-2 hover:bg-aquaSpring mb-4 px-2' key={key}>
          <div className='flex items-center'>
            <p className='flex items-center mr-2'>{NOTIFICATIONS_VARIANTS_TEXT[key]}</p>
            <IconWithTooltips tooltips={key as THelpKeys} className='mb-1' />
          </div>
          <div className='grid grid-cols-2'>
            <SettingsCheckbox
              htmlFor={key}
              checked={!!notificationsSettings?.owner[key as keyof TNotificationItem]}
              onChangeHandler={onCheckBoxChangeHandler('owner')}
              variant='owner'
              isShowInfoIcon={false}
            />
            <SettingsCheckbox
              htmlFor={key}
              checked={!!notificationsSettings?.performer[key as keyof TNotificationItem]}
              onChangeHandler={onCheckBoxChangeHandler('performer')}
              variant='performer'
              isShowInfoIcon={false}
            />
          </div>
        </div>
      );
    });
  }, [notificationsSettings, onCheckBoxChangeHandler]);

  const isSettingsChanged = useMemo(() => {
    if (!notificationsSettings || !oldNotificationsSettings) return false;

    return !isEqual(notificationsSettings, oldNotificationsSettings);
  }, [notificationsSettings, oldNotificationsSettings]);

  const closeSaveExitModal = useCallback(() => {
    dispatch(setOldNotificationSettings(notificationsSettings || defaultNotificationSettings));
    dispatch(setIsShowNoSaveExitModal(false));
    setTimeout(() => {
      clickOnElement({
        x: clickCoordinates.clientX,
        y: clickCoordinates.clientY,
        overlayElement: ref.current,
      });
    }, 150);
  }, [clickCoordinates, dispatch, notificationsSettings]);

  const onSaveSettings = () => {
    const saveData = notificationsSettings || defaultNotificationSettings;
    dispatch(
      saveNotificationsThunk({
        ...saveData,
        successCallback: closeSaveExitModal,
      }),
    );
  };

  const closeElementCallback = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
      if (isSettingsChanged && event) {
        setClickCoordinates({
          clientX: event.clientX,
          clientY: event.clientY,
        });

        ref.current = event.currentTarget;

        dispatch(setIsShowNoSaveExitModal(true));
      }
    },
    [isSettingsChanged, dispatch],
  );

  return (
    <div className='mb-8 max-w-[45.75rem] w-full'>
      {isSettingsChanged && !isLoading
        ? createPortal(
            <Overlay onClickCallback={closeElementCallback} className='!z-[1]' />,
            document.body,
          )
        : null}
      <h2 className='font-medium text-black text-h3Mobile leading-8 tracking-[0.0075em] mb-4'>
        Настройки уведомлений
      </h2>
      <div className='flex flex-col mb-8'>
        <div className='grid grid-cols-2 px-2'>
          <h3 className='font-bold text-gray3 text-body3Mobile mb-4'>Тип события</h3>
          <h3 className='font-bold text-gray3 text-body3Mobile mb-4'>Кого уведомлять</h3>
        </div>
        <div className='flex flex-col'>{renderList}</div>
      </div>
      <Button
        dense
        type='action'
        view='filled'
        color='success'
        loading={notificationsSettings && isLoading}
        text='Сохранить настройки'
        onClick={onSaveSettings}
        disabled={isLoading}
        className={`relative w-fit ${isSettingsChanged ? '!z-[2]' : ''}`}
      />
      {isShowNoSaveExitModal ? (
        <NoteSuccessModal
          isOpen={isShowNoSaveExitModal}
          isLoading={isLoading}
          successButtonText='Сохранить настройки'
          cancelButtonText='Выйти без сохранения'
          title='Сохраните настройки перед выходом'
          description='Вы пытаетесь выйти без сохранения настроек.'
          successButtonCallback={onSaveSettings}
          cancelButtonCallback={closeSaveExitModal}
        />
      ) : null}
      <ToastsMessages
        successToastTimeout={2000}
        isShowErrorToast={isErrorNotificationSave}
        errorMessage='Ошибка сохранения настроек уведомлений'
        successMessage='Настройки уведомлений успешно сохранены'
        isShowSuccessToast={isSuccessNotificationSave}
        clearErrorCallback={onHideToastHandler}
        clearSuccessStatusCallback={onHideToastHandler}
      />
    </div>
  );
};
