import { storageDb } from '@api/storageApi';
import { TRejectValue } from '@models/index';
import { settings } from '@api/settings/settings';
import { getEventData, log } from '@helpers/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TCommonData, TPageEdit, TPageView } from '@helpers/myTracker/type';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import {
  postEvent,
  PAGE_NAMES,
  MY_TRACKER_EVENTS,
  PAGE_CHANGES_TYPE,
} from '@helpers/myTracker/myTracker';
import {
  GetAdminsTableDataResponseSchema,
  GetSettingResponseDataSchema,
  GetSmartScheduleResponseSchema,
  ORDER_VARIANTS,
  SettingsNotificationResponseDataSchema,
  SettingsTelephonyDataResponseSchema,
  StaffListItemSchema,
  TAdminsListItem,
  TELEPHONY_RESPONSE_KEY_NAMES,
  TelephonyResponseSchema,
  TSettingsData,
  TSettingsNotificationResponseData,
  TSettingStaff,
  TSettingsTelephonyData,
  TStaffListItem,
  TTelephonyRequestData,
  TTelephonyTablePageData,
} from '@models/Settings';
import {
  settingsMappers,
  settingsTelephonyMappers,
  telephonyMappers,
} from '@redux/Settings/mappers';
import {
  REJECT_RESPONSE_KEY,
  SETTINGS_RESPONSE_KEY,
  T_STAFF_LIST_RESPONSE_KEYS,
  T_SETTINGS_TELEPHONY_RESPONSE_KEYS,
} from '@api/types';

export const getSettings = createAsyncThunk<TSettingsData | void, void, { rejectValue: string }>(
  'settings/getSettingsData',
  (_, { dispatch, rejectWithValue }) => {
    return settings
      .getSettings()
      .then(response => {
        if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error(String(response.data.status));
        }
        if (SETTINGS_RESPONSE_KEY.CALL_STATS in response.data) {
          const parsedData = GetSettingResponseDataSchema.safeParse(response.data);

          if (!parsedData.success) {
            log('@@getSettings: ', parsedData.error?.format());
            throw new Error(String(parsedData.error));
          }

          return settingsMappers.settingsResponseToStore(parsedData.data);
        }
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(getSettings()), 200);
          return rejectWithValue(String(error.response.data.detail));
        }
        return rejectWithValue(String(error.response.status));
      });
  },
);

export const postSettings = createAsyncThunk<
  TSettingsData | void,
  TSettingsData & { successCallback?: () => void },
  { rejectValue: string }
>('settings/postSettings', ({ successCallback, ...data }, { dispatch, rejectWithValue }) => {
  return settings
    .postSettings(settingsMappers.mapSettingsDataToBack(data))
    .then(response => {
      if (SETTINGS_RESPONSE_KEY.CALL_STATS in response.data) {
        postEvent<TPageView & TPageEdit>(
          getEventData<TPageView & TPageEdit>({
            eventName: MY_TRACKER_EVENTS.LK_PAGE_CHANGE,
            eventProperties: {
              name_page: PAGE_NAMES.SETTINGS_COMMON,
              type_changes: PAGE_CHANGES_TYPE.STRUCTURE,
            },
          }),
        );

        const parsedData = GetSettingResponseDataSchema.safeParse(response.data);

        if (!parsedData.success) {
          log('@@postSettings: ', parsedData.error?.format());
          throw new Error(String(parsedData.error));
        }

        if (successCallback) successCallback();
        return settingsMappers.settingsResponseToStore(parsedData.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(postSettings(data)), 200);
        return rejectWithValue(String(error.response.data.detail));
      }
      return rejectWithValue(String(error.response.status));
    });
});

export const getSettingsPageStaffList = createAsyncThunk<
  { data: TStaffListItem[]; categoryName: string } | void,
  void,
  TRejectValue
>('settings/getSettingsPageStaffList', (data, { dispatch, rejectWithValue }) => {
  return settings
    .getExceptionStatsMaster()
    .then(response => {
      if (T_STAFF_LIST_RESPONSE_KEYS.DATA in response.data) {
        const parsedData = StaffListItemSchema.array().safeParse(response.data.data);

        if (!parsedData.success) {
          log('@@getSettingsPageStaffList: ', parsedData.error?.format());
          throw new Error(String(parsedData.error));
        }

        return { data: parsedData.data, categoryName: '' };
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getSettingsPageStaffList()), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

export const getSmartScheduleTableData = createAsyncThunk<
  TSettingStaff[] | void,
  void,
  { rejectValue: string }
>('settings/getSmartScheduleTableData', (data, { dispatch, rejectWithValue }) => {
  return settings
    .getSmartSchedule()
    .then(response => {
      if (Array.isArray(response.data)) {
        const parsedData = GetSmartScheduleResponseSchema.array().safeParse(response.data);

        if (!parsedData.success) {
          log('@@getSmartScheduleTableData: ', parsedData.error?.format());
          throw new Error(String(parsedData.error));
        }

        return settingsMappers.smartScheduleResponseToStore(parsedData.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getSmartScheduleTableData()), 200);
        return rejectWithValue(String(error.response.data.detail));
      }
      return rejectWithValue(String(error.response.status));
    });
});

export const getAdminsTableData = createAsyncThunk<
  TAdminsListItem[] | void,
  void,
  { rejectValue: string }
>('settings/getAdminsTableData', (_, { dispatch, rejectWithValue }) => {
  return settings
    .getAdmins()
    .then(response => {
      if (Array.isArray(response.data)) {
        const parsedData = GetAdminsTableDataResponseSchema.array().safeParse(response.data);

        if (!parsedData.success) {
          log('@@getAdminsTableData: ', parsedData.error?.format());
          throw new Error(String(parsedData.error));
        }

        return settingsMappers.adminsResponseToStore(parsedData.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getAdminsTableData()), 200);
        return rejectWithValue(String(error.response.data.detail));
      }
      return rejectWithValue(String(error.response.status));
    });
});

export const saveAdminsTableData = createAsyncThunk<
  TAdminsListItem[] | void,
  { tableData: TAdminsListItem[]; successCallback?: () => void },
  { rejectValue: string }
>('settings/saveAdminsTableData', (data, { dispatch, rejectWithValue }) => {
  const dataToBack = settingsMappers.adminsDataToBack(data.tableData);
  return settings
    .postAdmins(dataToBack)
    .then(response => {
      if (Array.isArray(response.data)) {
        postEvent<TPageView & TPageEdit>(
          getEventData<TPageView & TPageEdit>({
            eventName: MY_TRACKER_EVENTS.LK_PAGE_CHANGE,
            eventProperties: {
              name_page: PAGE_NAMES.SETTINGS_ADMINS,
              type_changes: PAGE_CHANGES_TYPE.STRUCTURE,
            },
          }),
        );

        const parsedData = GetAdminsTableDataResponseSchema.array().safeParse(response.data);

        if (!parsedData.success) {
          log('@@saveAdminsTableData: ', parsedData.error?.format());
          throw new Error(String(parsedData.error));
        }

        if (data.successCallback) {
          data.successCallback();
        }

        return settingsMappers.adminsResponseToStore(parsedData.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(saveAdminsTableData(data)), 200);
        return rejectWithValue(String(error.response.data.detail));
      }
      return rejectWithValue(String(error.response.status));
    });
});

export const getNotificationsThunk = createAsyncThunk<
  TSettingsNotificationResponseData,
  void,
  { rejectValue: string }
>('settings/getNotificationsThunk', (_, { dispatch, rejectWithValue }) => {
  return settings
    .getNotifications()
    .then(response => {
      const parsedData = SettingsNotificationResponseDataSchema.safeParse(response.data);

      if (!parsedData.success) {
        log('@@getNotificationsThunk: ', parsedData.error?.format());
        throw new Error(String(parsedData.error));
      }

      return parsedData.data;
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getNotificationsThunk()), 200);
        return rejectWithValue(String(error.response.data.detail));
      }
      return rejectWithValue(String(error.response.status));
    });
});

export const saveNotificationsThunk = createAsyncThunk<
  TSettingsNotificationResponseData,
  TSettingsNotificationResponseData & { successCallback?: () => void },
  { rejectValue: string }
>(
  'settings/saveNotificationsThunk',
  ({ successCallback, ...data }, { dispatch, rejectWithValue }) => {
    return settings
      .saveNotifications(data)
      .then(response => {
        postEvent<TPageView & TPageEdit>(
          getEventData<TPageView & TPageEdit>({
            eventName: MY_TRACKER_EVENTS.LK_PAGE_CHANGE,
            eventProperties: {
              name_page: PAGE_NAMES.SETTINGS_NOTIFICATION,
              type_changes: PAGE_CHANGES_TYPE.STRUCTURE,
            },
          }),
        );

        const parsedData = SettingsNotificationResponseDataSchema.safeParse(response.data);

        if (!parsedData.success) {
          log('@@saveNotificationsThunk: ', parsedData.error?.format());
          throw new Error(String(parsedData.error));
        }

        if (successCallback) successCallback();
        return parsedData.data;
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(getNotificationsThunk()), 200);
          return rejectWithValue(String(error.response.data.detail));
        }
        return rejectWithValue(String(error.response.status));
      });
  },
);

/**
 * Thunk для получения данных по телефонии.
 *
 * @return { TSettingsTelephonyData | void} Данные по телефонии или void.
 * @param {void}
 */
export const getSettingsTelephonyThunk = createAsyncThunk<
  TSettingsTelephonyData | void,
  void,
  { rejectValue: string }
>('settingsTelephony/getSettingsTelephonyThunk', (_, { dispatch, rejectWithValue }) => {
  return settings
    .getTelephonyData()
    .then(response => {
      if (T_SETTINGS_TELEPHONY_RESPONSE_KEYS.TELEPHONY_LIST in response.data) {
        const parsedData = SettingsTelephonyDataResponseSchema.safeParse(response.data);

        if (!parsedData.success) {
          log('@@getSettingsTelephonyThunk: ', parsedData.error?.format());
          throw new Error(String(parsedData.error));
        }

        return settingsTelephonyMappers.responseToStore(parsedData.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getSettingsTelephonyThunk()), 200);
        return rejectWithValue(String(error.response.data.detail));
      }
      return rejectWithValue(String(error.response.status));
    });
});

/**
 * Thunk для проверки возможности подключения телефонии с указанными данными
 * @param data {TSettingsTelephonyData} данные с настройками телефонии
 * @return {void}
 */
export const testSettingsTelephony = createAsyncThunk<
  void,
  TSettingsTelephonyData,
  { rejectValue: string }
>('settingsTelephony/testSettingsTelephony', (data, { dispatch, rejectWithValue }) => {
  const { branchId } = storageDb.getRequestData();
  const dataToRequest = settingsTelephonyMappers.storeToRequest({ ...data, branchId });

  return settings
    .testTelephonyData(dataToRequest)
    .then(() => {
      // console.log(response.data);
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(testSettingsTelephony(data)), 200);
        return rejectWithValue(String(error.response.data.detail));
      }
      return rejectWithValue(String(error.response.status));
    });
});

/**
 * Thunk для сохранения(подключения) данных телефонии
 * @param data {TSettingsTelephonyData} данные с настройками телефонии
 * @return {void}
 */
export const saveSettingsTelephony = createAsyncThunk<
  void,
  TSettingsTelephonyData,
  { rejectValue: string }
>('settingsTelephony/saveSettingsTelephony', (data, { dispatch, rejectWithValue }) => {
  const { branchId } = storageDb.getRequestData();
  const dataToRequest = settingsTelephonyMappers.storeToRequest({ ...data, branchId });
  return settings
    .saveTelephonyData(dataToRequest)
    .then(() => {
      postEvent<TCommonData>(
        getEventData({
          eventName: MY_TRACKER_EVENTS.TELEPHONY_CONNECTED,
          eventProperties: {},
        }),
      );
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(saveSettingsTelephony(data)), 200);
        return rejectWithValue(String(error.response.data.detail));
      }
      return rejectWithValue(String(error.response.status));
    });
});

export const getTelephonyPageData = createAsyncThunk<
  TTelephonyTablePageData | void,
  TTelephonyRequestData,
  TRejectValue
>('telephonyTableSlice/getTelephonyPageData', (data, { dispatch, rejectWithValue }) => {
  return settings
    .getTelephonyTableData(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      if (TELEPHONY_RESPONSE_KEY_NAMES.PAGINATOR in response.data) {
        const parsedData = TelephonyResponseSchema.safeParse(response.data);

        if (!parsedData.success) {
          log('@@getTelephonyPageData: ', parsedData.error?.format());
          throw new Error(String(parsedData.error));
        }

        return telephonyMappers.responseToStore(parsedData.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getTelephonyPageData(data)), 100);
      }
      return rejectWithValue(error.response.data);
    });
});

export const setOrderThunk = createAsyncThunk<
  void,
  { target: ORDER_VARIANTS; data: string[] },
  { rejectValue: string }
>('settings/setOrderThunk', (data, { dispatch, rejectWithValue }) => {
  return settings
    .saveOrderOnDnD(data)
    .then(() => {
      const pageName =
        data.target === ORDER_VARIANTS.STATS ? PAGE_NAMES.REPORTS : PAGE_NAMES.TASKS_ALL;

      postEvent<TPageView & TPageEdit>(
        getEventData<TPageView & TPageEdit>({
          eventName: MY_TRACKER_EVENTS.LK_PAGE_CHANGE,
          eventProperties: {
            name_page: pageName,
            type_changes: PAGE_CHANGES_TYPE.STRUCTURE,
          },
        }),
      );
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(setOrderThunk(data)), 200);
        return rejectWithValue(String(error.response.data.detail));
      }
      return rejectWithValue(String(error.response.status));
    });
});
