import React, { forwardRef, memo, useCallback, useMemo, useState } from 'react';
//
import { Descendant } from 'slate';
import { Dialog } from '@uikit/Dialog';
import { Button } from '@uikit/Button';
import { TASK_TYPE } from '@const/task';
import { useEmoji } from '@hooks/useEmoji';
import { reportPreviewDict } from '@const/report';
import { EmojiClickData } from 'emoji-picker-react';
import { TaskPreview } from '@components/taskPreview';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { THelpKeys, TSimpleStringObj } from '@models/index';
import { TChildRef, TEPNameList, TOnChangeHandler } from '@shared/types';
import { TextAreaWithSlateEditor } from '@blocks/textareaWithSlateEditor';

interface ITaskBlockProps {
  /**
   * Название задачи
   * @param {string}
   */
  taskName: string;
  /**
   * Флаг отключает возможность ввода значений в поля
   * @param {boolean}
   */
  disabled: boolean;
  /**
   * Описание задачи
   * @param {string}
   */
  taskDescription: string;
  /**
   * OnChange обработчик для input с названием задачи
   * @param {TOnChangeHandler}
   */
  setTaskNameHandler: TOnChangeHandler;
  /**
   * OnChange обработчик для textarea с описанием задачи
   * @param {(newValue: Descendant[]) => void}
   */
  setTaskDescriptionHandler: (newValue: Descendant[]) => void;
  /**
   * Значение параметра name для input, необходимо для OnChangeHandlers
   * @param {string}
   */
  templateInputName: string;
  /**
   * Значение параметра name для textarea, необходимо для OnChangeHandlers
   * @param {THelpKeys}
   */
  templateDescription: THelpKeys;
  /**
   * Placeholder для элемента input
   * @param {string}
   */
  templateInputPlaceholder: string;
  /**
   * Устанавливает поле название обязательным
   * @param {boolean}
   */
  nameIsRequired: boolean;
  /**
   * Устанавливает поле описание обязательным
   * @param {boolean}
   */
  descriptionIsRequired: boolean;
  /**
   * Устанавливает максимальное количество символов для textarea
   * @param {number}
   */
  textareaValueMaxLength: number;
  /**
   * Опциональный параметр callback вызывается при событии onBlur на элементе textarea
   * @param {(event: React.FocusEvent<HTMLTextAreaElement>) => void}
   */
  onTextareaBlurHandler?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  /**
   * Параметр отвечающий за определиние типа страницы
   * @param {TEPNameList}
   */
  type: TEPNameList;
  setEmojiInTextarea?: (emoji: EmojiClickData) => void;
  /**
   * Объект с ключами
   * @param {TSimpleStringObj}
   */
  dictForKeys: TSimpleStringObj;
  /**
   * Тип задачи
   * @param {TASK_TYPE}
   */
  taskType?: TASK_TYPE;
  /**
   * Список для формирования чеклиста
   * @param {string[]}
   */
  checklistItems?: string[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

const taskDescriptionComponent = forwardRef<TChildRef, ITaskBlockProps>(
  (
    {
      type,
      disabled,
      taskName,
      taskType,
      dictForKeys,
      checklistItems,
      nameIsRequired,
      taskDescription,
      templateInputName,
      setEmojiInTextarea,
      setTaskNameHandler,
      templateDescription,
      onTextareaBlurHandler,
      descriptionIsRequired,
      textareaValueMaxLength,
      templateInputPlaceholder,
      setTaskDescriptionHandler,
      className = '',
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { setViewEmojiPicker } = useEmoji({ type });

    const modalToggle = useCallback(() => setIsOpen(prevState => !prevState), []);

    const memoizedDialog = useMemo(
      () => (
        <Dialog
          type='default'
          view='raised'
          color='default'
          isOpen={isOpen}
          backdropOpacity={40}
          title='Превью задачи'
          onClose={modalToggle}
          text={
            isOpen ? (
              <TaskPreview
                isTaskPreview
                taskName={taskName}
                taskType={taskType}
                keys={reportPreviewDict}
                taskCheckList={checklistItems}
                taskDescription={taskDescription}
              />
            ) : (
              <span />
            )
          }
          className='whitespace-pre-line break-words report-preview'
        />
      ),
      [isOpen, modalToggle, taskName, taskType, checklistItems, taskDescription],
    );

    const memoizedShowPreviewButton = useMemo(
      () => (
        <Button
          dense
          type='default'
          color='default'
          view='outlined'
          onClick={modalToggle}
          text='Показать превью'
          rightIcon='application'
          className='!flex w-[11rem] mb-2'
        />
      ),
      [modalToggle],
    );

    return (
      <div className={className}>
        <div className='mb-4'>
          <div className='flex flex-col sm:flex-row sm:items-center justify-between mb-2'>
            <p className='text-black text-h3Mobile mb-2 mr-2'>Задача</p>
            {type === 'task' ? memoizedShowPreviewButton : null}
          </div>
          <p className='text-grayText mb-1 tracking-[0.0275em]'>
            Название {nameIsRequired ? <span className='text-red'>*</span> : null}
          </p>
          <TextInput
            fill
            type='text'
            view='outlined'
            value={taskName}
            disabled={disabled}
            id={templateInputName}
            name={templateInputName}
            onChange={setTaskNameHandler}
            onBlur={onTextareaBlurHandler}
            placeholder={templateInputPlaceholder}
            error={nameIsRequired && !taskName && !disabled}
            color={nameIsRequired && !taskName && !disabled ? 'danger' : 'default'}
          />
        </div>
        <div className=''>
          <TextAreaWithSlateEditor
            isEmoji
            ref={ref}
            isCheckKeys
            keys={dictForKeys}
            disabled={disabled}
            value={taskDescription}
            isError={!taskDescription}
            htmlFor={templateDescription}
            isRequired={descriptionIsRequired}
            setViewEmojiPicker={setViewEmojiPicker}
            setEmojiInTextarea={setEmojiInTextarea}
            maxLength={textareaValueMaxLength || 2000}
            onChangeHandler={setTaskDescriptionHandler}
            showInfo={false}
          />
        </div>
        {type === 'task' ? memoizedDialog : null}
      </div>
    );
  },
);

export const TaskDescription = memo(taskDescriptionComponent);
