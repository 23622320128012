import React, { useEffect, useMemo } from 'react';
//
import { getEventData } from '@helpers/index';
import { NoAccess } from '@components/noAccess';
import { getSettingsData } from '@redux/selectors';
import { ErrorMessage } from '@blocks/errorMessage';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { OPERATOR_DATA, OPERATOR_NAMES } from '@const/settings';
import { CONNECTION_TYPE, TConnectionType, TSettingsTelephonyResponseData } from '@models/Settings';
import { TPageView, TStartTelephonyConnection } from '@helpers/myTracker/type';
import { SettingsOperatorData } from '@components/settingsOperatorData';
import { SettingsTelephonyHeader } from '@blocks/settingsTelephonyHeader';
import { SettingsConnectionType } from '@components/settingsConnectionType';
import { SettingsTelephonyCommercial } from '@blocks/settingsTelephonyCommercial';
import { SettingsTelephonyOperator } from '@components/settingsTelephonyOperator';
import { SettingsTelephonyMessages } from '@components/settingsTelephonyMessages';
import { setConnectionType, setEditMode, setFormStep } from '@redux/Settings/slice';
import { MY_TRACKER_EVENTS, PAGE_NAMES, postEvent } from '@helpers/myTracker/myTracker';
import {
  saveSettingsTelephony,
  testSettingsTelephony,
  getSettingsTelephonyThunk,
} from '@redux/Settings/thunk';

type TTelephonyProps = {
  /**
   * Флаг показывает/скрывает рекламу провайдера
   * @param {boolean}
   */
  showCommercial: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const Telephony = ({ showCommercial, className = '' }: TTelephonyProps) => {
  const dispatch = useAppDispatch();
  const {
    webhook,
    isError,
    formStep,
    isSuccess,
    isLoading,
    isEditMode,
    manualDocs,
    yclientsDocs,
    isAccessDenied,
    connectionError,
    isTestingConnection,
    connectionTestError,
    connectionInProgress,
    isShowSuccessMessage,
    connectionTestSuccess,
    isShowAlmostDoneMessage,
    telephonyData: data,
  } = useAppSelector(getSettingsData);

  const {
    operators,
    phoneNumber,
    connectionType,
    selectedOperator,
    connectionStatus,
    possibleConnectionTypes,
    selectedTelephonyData,
  } = data;

  useEffect(() => {
    postEvent<TPageView>(
      getEventData<TPageView>({
        eventName: MY_TRACKER_EVENTS.LK_PAGE_VIEW,
        eventProperties: { name_page: PAGE_NAMES.SETTINGS_TELEPHONY },
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(getSettingsTelephonyThunk());
  }, [dispatch]);

  const selectedOperatorData = useMemo(() => {
    return operators.find(operator => operator.name === selectedOperator);
  }, [selectedOperator, operators]);

  // Обработчик изменения типа подключения
  const setConnectionTypeHandler = (type: TConnectionType) => {
    if (formStep === 1) {
      if (type === CONNECTION_TYPE.YCLIENTS) {
        dispatch(
          testSettingsTelephony({
            ...data,
            connectionType: type,
          }),
        );
      }
      dispatch(setConnectionType(type));
    }
  };

  // Проверяет возможность подключить телефонию с указанными данными
  const checkConnectionHandler = (type: TConnectionType) => () => {
    dispatch(
      testSettingsTelephony({
        ...data,
        connectionType: type,
      }),
    );
  };

  // Сохраняет настройки телефонии после подключения хука пользователем
  const webhookSetupConfirmHandler = () => {
    dispatch(saveSettingsTelephony(data));
  };

  // Флаг заполнения полей в первом боке настроек
  const isFirstDataBlockFilled =
    selectedOperator !== OPERATOR_NAMES.NOT_SELECTED && phoneNumber.length > 9;

  // Флаг определяет показывать или нет блок выбора типа подключения
  const isShowTelephonyConnectionComponent =
    possibleConnectionTypes.yclients && possibleConnectionTypes.manual;

  // Устанавливается ссылка на инструкцию в зависимости от выбранного типа подключения
  const instructionLinkToMessage =
    connectionType === CONNECTION_TYPE.YCLIENTS ? yclientsDocs : manualDocs;

  // Иконка для кнопки в первом блоке настроек
  const firstBlockButtonIcon =
    !isEditMode && isFirstDataBlockFilled && formStep > 0 ? 'edit' : 'small-tick';

  // Флаг показа сообщения об ошибке
  const isShowConnectionErrorMessage =
    (connectionTestError || connectionError) && connectionType === CONNECTION_TYPE.YCLIENTS;

  // Флаг показывает блок настройки домена и apiKey
  const isShowSettingsOperatorData =
    formStep === 1 && connectionType === CONNECTION_TYPE.MANUAL && !connectionTestSuccess;

  // Обрабатывает изменение режима редактирования
  const setIsEditModeHandler = (state: boolean) => {
    dispatch(setEditMode(state));
  };

  const sendEventHandler = () => {
    postEvent<TStartTelephonyConnection>(
      getEventData<TStartTelephonyConnection>({
        eventName: MY_TRACKER_EVENTS.START_CONNECT_TELEPHONY,
        eventProperties: {
          operator: selectedOperator,
        },
      }),
    );
  };

  // Обработчик нажатия на кнопку в первом боке настроек
  const confirmButtonHandler = () => {
    if (isFirstDataBlockFilled && formStep === 0 && connectionType === CONNECTION_TYPE.YCLIENTS) {
      checkConnectionHandler(CONNECTION_TYPE.YCLIENTS)();
      setIsEditModeHandler(false);
      sendEventHandler();
    } else if (
      isFirstDataBlockFilled &&
      formStep === 0 &&
      connectionType === CONNECTION_TYPE.MANUAL
    ) {
      setIsEditModeHandler(false);
      dispatch(setFormStep(1));
      sendEventHandler();
    } else {
      setIsEditModeHandler(true);
    }
  };

  return (
    <div className={className}>
      {isError ? <ErrorMessage className='h-[50Vh]' /> : null}
      {isAccessDenied ? <NoAccess /> : null}
      {!isError && !isLoading && isSuccess && !isAccessDenied ? (
        <div className='border border-quartz1 rounded-lg p-4 max-w-[57.5rem] w-full'>
          <SettingsTelephonyHeader connectionStatus={connectionStatus} className='mb-4' />
          <SettingsTelephonyOperator
            operators={operators}
            phoneNumber={phoneNumber}
            buttonIcon={firstBlockButtonIcon}
            selectedOperator={selectedOperator}
            disabledButton={!isFirstDataBlockFilled}
            confirmButtonHandler={confirmButtonHandler}
            selectedTelephonyData={selectedTelephonyData as TSettingsTelephonyResponseData}
            className='mb-[1.375rem]'
          />
          {isFirstDataBlockFilled && formStep > 0 ? (
            <SettingsConnectionType
              connectionType={connectionType}
              setConnectionTypeHandler={setConnectionTypeHandler}
              isShowTelephonyConnectionComponent={isShowTelephonyConnectionComponent}
              className='mb-6'
            />
          ) : null}
          {isShowSettingsOperatorData && !connectionInProgress ? (
            <SettingsOperatorData
              data={data}
              manualInstructionHref={manualDocs}
              isCheckConnectionError={connectionTestError}
              fields={selectedOperatorData?.fields || []}
              checkConnection={checkConnectionHandler(CONNECTION_TYPE.MANUAL)}
              className='mb-6'
            />
          ) : null}
          {formStep > 0 ? (
            <SettingsTelephonyMessages
              webhook={webhook}
              isSuccess={isShowSuccessMessage}
              isAlmostDone={isShowAlmostDoneMessage}
              instructionLink={instructionLinkToMessage}
              isConnectionError={isShowConnectionErrorMessage}
              webhookSetupConfirm={webhookSetupConfirmHandler}
              isLoading={isTestingConnection || connectionInProgress}
              checkConnection={checkConnectionHandler(CONNECTION_TYPE.YCLIENTS)}
            />
          ) : null}
          {showCommercial && formStep === 0 ? (
            <SettingsTelephonyCommercial
              link={OPERATOR_DATA.link}
              telephonyName={OPERATOR_DATA.name}
              promoCode={OPERATOR_DATA.promoCode}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
