import { createPortal } from 'react-dom';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
//
import { Button } from '@uikit/Button';
import isEqual from '@helpers/isEqual';
import { Overlay } from '@atoms/overlay';
import { TPageView } from '@helpers/myTracker/type';
import { SETTINGS_DATA_KEY_NAMES } from '@const/settings';
import { ToastsMessages } from '@components/toastsMessages';
import { KPP_LENGTH, MAX_INN_LENGTH, MIN_INN_LENGTH, MIN_NAME_LENGTH } from '@const/pay';
import { clickOnElement, getEventData } from '@helpers/index';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { NoteSuccessModal } from '@components/noteSuccessModal';
import { IconWithTooltips } from '@components/iconWithTooltips';
import { setIsShowNoSaveExitModal } from '@redux/Settings/slice';
import { getPayPageState, getSettingsData } from '@redux/selectors';
import { SettingInputWithLabel } from '@blocks/settingInputWithLabel';
import { getPayerDataThunk, setPayerDataThunk } from '@redux/Pay/thunk';
import { MY_TRACKER_EVENTS, PAGE_NAMES, postEvent } from '@helpers/myTracker/myTracker';
import {
  setPayerInn,
  setPayerKpp,
  setPayerName,
  setPayerFormOldData,
  clearPayToastMessages,
} from '@redux/Pay/slice';

export const SettingsPayTemplate = () => {
  const ref = useRef<(EventTarget & Element) | undefined>(undefined);
  const [clickCoordinates, setClickCoordinates] = useState({
    clientX: 0,
    clientY: 0,
  });
  const {
    isPayerDataSaving,
    isLoading,
    successToastMessage,
    errorToastMessage,
    payerFormData,
    payerFormOldData,
  } = useAppSelector(getPayPageState);
  const { isShowNoSaveExitModal } = useAppSelector(getSettingsData);
  const dispatch = useAppDispatch();

  useEffect(() => {
    postEvent<TPageView>(
      getEventData<TPageView>({
        eventName: MY_TRACKER_EVENTS.LK_PAGE_VIEW,
        eventProperties: { name_page: PAGE_NAMES.SETTINGS_BILL_DATA },
      }),
    );
  }, []);

  const { name, inn, kpp } = payerFormData;

  const validName = !!name && name.length >= MIN_NAME_LENGTH;
  const validInn = !!inn && inn.length >= MIN_INN_LENGTH && inn.length <= MAX_INN_LENGTH;
  const validKpp = !!kpp && kpp.length === KPP_LENGTH;

  const setPayerNameHandler = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setPayerName(value));
    },
    [dispatch],
  );

  const setPayerInnHandler = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setPayerInn(value));
    },
    [dispatch],
  );

  const setPayerKppHandler = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setPayerKpp(value));
    },
    [dispatch],
  );

  const clearInputHandler = useCallback(
    (variant: SETTINGS_DATA_KEY_NAMES) => () => {
      switch (variant) {
        case SETTINGS_DATA_KEY_NAMES.INN:
          dispatch(setPayerInn(''));
          break;
        case SETTINGS_DATA_KEY_NAMES.KPP:
          dispatch(setPayerKpp(''));
          break;
        default:
          dispatch(setPayerName(''));
      }
    },
    [dispatch],
  );

  const isSettingsChanged = useMemo(
    () => !isEqual(payerFormData, payerFormOldData),
    [payerFormData, payerFormOldData],
  );

  const closeSaveExitModal = useCallback(() => {
    dispatch(setPayerFormOldData(payerFormData));
    dispatch(setIsShowNoSaveExitModal(false));
    setTimeout(() => {
      clickOnElement({
        x: clickCoordinates.clientX,
        y: clickCoordinates.clientY,
        overlayElement: ref.current,
      });
    }, 150);
  }, [clickCoordinates, dispatch, payerFormData]);

  const onSaveHandler = useCallback(() => {
    if (validName && validInn) {
      dispatch(
        setPayerDataThunk({
          payerData: payerFormData,
          successCallback: closeSaveExitModal,
        }),
      );
    }
  }, [dispatch, payerFormData, validInn, validName, closeSaveExitModal]);

  const clearToastMessages = useCallback(() => {
    dispatch(clearPayToastMessages());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPayerDataThunk());

    return () => {
      clearToastMessages();
    };
  }, [dispatch, clearToastMessages]);

  const closeElementCallback = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
      if (isSettingsChanged && event) {
        setClickCoordinates({
          clientX: event.clientX,
          clientY: event.clientY,
        });

        ref.current = event.currentTarget;

        dispatch(setIsShowNoSaveExitModal(true));
      }
    },
    [isSettingsChanged, dispatch],
  );

  return (
    <div className='relative mb-8 max-w-[45.75rem] w-full z-[2]'>
      {isSettingsChanged && !isLoading
        ? createPortal(
            <Overlay onClickCallback={closeElementCallback} className='!z-[1]' />,
            document.body,
          )
        : null}
      <div className='flex items-center mb-4'>
        <p className='flex items-center font-medium text-black text-h3Mobile tracking-[0.0075em] mr-2'>
          Данные счета
        </p>
        <IconWithTooltips tooltips='billData' className='mb-1' />
      </div>
      <SettingInputWithLabel
        isRequire
        error={!validName}
        errorText='Некорректное наименование юр.лица'
        value={payerFormData.name || ''}
        onChangeHandler={setPayerNameHandler}
        htmlFor={SETTINGS_DATA_KEY_NAMES.NAME}
        clearSearchString={clearInputHandler(SETTINGS_DATA_KEY_NAMES.NAME)}
        textColor='text-blackHeader'
        className='mb-8 w-full'>
        Наименование юр.лица
      </SettingInputWithLabel>
      <SettingInputWithLabel
        isRequire
        error={!validInn}
        errorText='Некорректный ИНН'
        value={payerFormData.inn || ''}
        onChangeHandler={setPayerInnHandler}
        htmlFor={SETTINGS_DATA_KEY_NAMES.INN}
        clearSearchString={clearInputHandler(SETTINGS_DATA_KEY_NAMES.INN)}
        textColor='text-blackHeader'
        className='mb-8 w-full'>
        ИНН
      </SettingInputWithLabel>
      <SettingInputWithLabel
        error={!!kpp && !validKpp}
        errorText='Некорректный КПП'
        value={payerFormData.kpp || ''}
        onChangeHandler={setPayerKppHandler}
        htmlFor={SETTINGS_DATA_KEY_NAMES.KPP}
        clearSearchString={clearInputHandler(SETTINGS_DATA_KEY_NAMES.KPP)}
        textColor='text-blackHeader'
        className='mb-8 w-full'>
        КПП
      </SettingInputWithLabel>
      <Button
        dense
        type='action'
        view='filled'
        color='success'
        loading={isPayerDataSaving}
        text='Сохранить настройки'
        onClick={onSaveHandler}
        disabled={isLoading || isPayerDataSaving || !validName || !validInn}
        className={`relative w-fit ${isSettingsChanged ? '!z-[2]' : ''}`}
      />
      {isShowNoSaveExitModal ? (
        <NoteSuccessModal
          isOpen={isShowNoSaveExitModal}
          isLoading={isPayerDataSaving}
          successButtonText='Сохранить настройки'
          cancelButtonText='Выйти без сохранения'
          title='Сохраните настройки перед выходом'
          description='Вы пытаетесь выйти без сохранения настроек.'
          successButtonCallback={onSaveHandler}
          cancelButtonCallback={closeSaveExitModal}
        />
      ) : null}
      <ToastsMessages
        errorMessage={errorToastMessage}
        successMessage={successToastMessage}
        isShowErrorToast={!!errorToastMessage}
        isShowSuccessToast={!!successToastMessage}
        clearSuccessStatusCallback={clearToastMessages}
        clearErrorCallback={clearToastMessages}
      />
    </div>
  );
};
