import React, { memo, useMemo } from 'react';
//
import { Button } from '@uikit/Button';
import { NavDropDown } from '@components/navDropDown';
import { TemplateNavItem } from '@blocks/templateNavItem';

interface ITemplateNavProps {
  /**
   * Путь из URL полученный хуком useLocation в родительском компоненте
   * @param {string}
   */
  path: string;
  /**
   * Параметр для отключения кнопки "новая задача"
   * @param {boolean}
   */
  isDisable: boolean;
  /**
   * Callback на кнопку новая задача
   * @param {() => void}
   */
  callBack: () => void;
  /**
   * Id выбранного филиала используется для формирования ссылок
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Словарь с путями и названием ссылок для формирования списка ссылок
   * @param {{ [key: string]: string }}
   */
  pageNameDict: { [key: string]: string };
  /**
   * Опциональный параметр, отображает или скрывает кнопку добавления шаблонов
   * @param {boolean}
   */
  isShowAddButton?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const TemplateNav = memo(
  ({
    path,
    callBack,
    isDisable,
    pageNameDict,
    isShowAddButton,
    selectedFilialAccId,
    className = '',
  }: ITemplateNavProps) => {
    const navItemsToRender = useMemo(
      () =>
        Object.entries(pageNameDict).map(item => (
          <TemplateNavItem
            key={item[0]}
            text={item[1]}
            isActive={path === item[0]}
            to={`${item[0]}?acc_id=${selectedFilialAccId}`}
            className={`mr-2 ${path === item[0] ? '!z-[2]' : ''}`}
          />
        )),
      [path, selectedFilialAccId, pageNameDict],
    );

    return (
      <div className={`flex flex-wrap justify-between ${className}`}>
        <div className='sm:hidden mb-4'>
          <NavDropDown pageNameDict={pageNameDict} className='nav_dropdown' />
        </div>
        <div className='flex-wrap sm:flex hidden mb-4'>{navItemsToRender}</div>
        {isShowAddButton ? (
          <Button
            dense
            icon='plus'
            type='action'
            view='filled'
            color='success'
            text='Новая задача'
            onClick={callBack}
            disabled={isDisable}
            className='w-full sm:w-auto mb-4'
          />
        ) : null}
      </div>
    );
  },
);
