import React, { forwardRef, memo, useEffect, useState } from 'react';
//
import { Icon } from '@atoms/icon';
import { Descendant } from 'slate';
import { createPortal } from 'react-dom';
import { TChildRef } from '@shared/types';
import { AddEmoji } from '@atoms/addEmoji';
import { FormLabel } from '@blocks/formLabel';
import { EmojiLib } from '@components/emojiLib';
import { getEmojiDate } from '@redux/selectors';
import { EmojiClickData } from 'emoji-picker-react';
import { slateEditorStringParser } from '@helpers/index';
import { THelpKeys, TSimpleStringObj } from '@models/index';
import { setHideEmojiPicker } from '@redux/emoji/emojiSlice';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import RichTextExample, { serializeStringNodes } from '@components/slateEditor/richTextEditor';

interface ITextAreaWithLabelProps {
  /**
   * Значение textarea
   * @param {string}
   */
  value: string;
  /**
   * Параметр htmlFor для label
   * @param {THelpKeys}
   */
  htmlFor: THelpKeys;
  /**
   * Объект ключей для проверки ключей на валидность
   * @param {TSimpleStringObj}
   */
  keys: TSimpleStringObj;
  /**
   * Флаг проверки ключей
   * @param {boolean}
   */
  isCheckKeys: boolean;
  /**
   * Опциональный параметр определяет показывать или нет иконку с всплывающей подсказкой
   * @param {boolean}
   */
  showInfo?: boolean;
  /**
   * Флаг ошибки устанавливает стили ошибки в textarea
   * @param {boolean}
   */
  isError?: boolean;
  /**
   * Опциональный параметр включает и выключает иконку Emoji
   * @param {boolean}
   */
  isEmoji?: boolean;
  /**
   * Опциональный параметр callback для открытия emojiPicker
   * @param {() => void}
   */
  showEmojiPicker?: () => void;
  /**
   * Опциональный параметр callback для записи выбранного эмодзи в textarea
   * @param {(emoji: EmojiClickData, event: MouseEvent) => void}
   */
  setEmojiInTextarea?: (emoji: EmojiClickData, event: MouseEvent) => void;
  /**
   * Опциональный параметр
   * @param {({ name, isOpenEmoji }: { name: string; isOpenEmoji: boolean }) => void}
   */
  setViewEmojiPicker?: ({ name, isOpenEmoji }: { name: string; isOpenEmoji: boolean }) => void;
  /**
   * Опциональный параметр ID шага
   * @param {number}
   */
  stepId?: number;
  /**
   * Опциональный параметр отключает textarea
   * @param {boolean}
   * @default
   */
  disabled?: boolean;
  /**
   * Опциональный параметр устанавливает максимальное количество знаков
   * @param {number}
   * @default
   */
  maxLength?: number;
  /**
   * Опциональный параметр добавляет * в label и устанавливает параметр required в textarea
   * @param {boolean}
   * @default
   */
  isRequired?: boolean;
  /**
   * Флаг скрывает метку над textarea
   * @param {boolean}
   */
  isHideLabel?: boolean;
  /**
   * Callback для изменения значения в textarea
   * @param {(newValue: Descendant[]) => void}
   */
  onChangeHandler: (newValue: Descendant[]) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

const textAreaWithSlateEditor = forwardRef<TChildRef, ITextAreaWithLabelProps>(
  (
    {
      keys,
      value,
      isEmoji,
      htmlFor,
      disabled,
      maxLength,
      isRequired,
      stepId = 0,
      isError = false,
      showInfo = true,
      isCheckKeys,
      onChangeHandler,
      isHideLabel = false,
      showEmojiPicker = () => '',
      setViewEmojiPicker = () => '',
      setEmojiInTextarea = () => '',
      className = '',
    },
    childRef,
  ) => {
    const dispatch = useAppDispatch();
    const [isViewEmoji, setIsViewEmoji] = useState(false);
    const [isInnerError, setIsInnerError] = useState(false);
    const [maxLengthError, setMaxLengthError] = useState(false);
    const { isOpen, textAreaName, stepIdEmoji } = useAppSelector(getEmojiDate);

    useEffect(() => {
      if (htmlFor === textAreaName && isOpen && stepId === stepIdEmoji) {
        setIsViewEmoji(true);
      } else {
        setIsViewEmoji(false);
      }
    }, [isOpen, textAreaName, htmlFor, stepId, stepIdEmoji]);

    // если в пропсах передали ошибку, выставляет ошибку локально
    useEffect(() => {
      setIsInnerError(isError);
    }, [isError]);

    // Ели передан параметр maxLength, устанавливает/убирает ошибку превышения максимальной длинны сообщения
    useEffect(() => {
      if (value && value.length > (maxLength || Infinity)) {
        if (!maxLengthError) {
          setMaxLengthError(true);
        }
      } else if (maxLength) {
        setMaxLengthError(false);
      }
    }, [value, maxLength, maxLengthError, isRequired]);

    // Устанавливает/снимает ошибку
    const errorClassToggle = (text: string) => () => {
      const textValue = serializeStringNodes(text).length;

      if (isRequired && !textValue) {
        setIsInnerError(true);
      }
      if (textValue) {
        setIsInnerError(false);
      }
    };

    const emojiPickerHandler = ({
      name,
      isOpenPicker,
    }: {
      name: string;
      isOpenPicker: boolean;
    }) => {
      setViewEmojiPicker({ name, isOpenEmoji: isOpenPicker });
      showEmojiPicker();
    };

    const onClickEmoji = () => {
      dispatch(setHideEmojiPicker({ name: '', isOpen: false, stepIdEmoji: 0 }));
    };

    const newValue = () => {
      try {
        const v = JSON.parse(value);
        if (Array.isArray(v)) {
          return v;
        }
        return slateEditorStringParser(value, keys);
      } catch (e) {
        return slateEditorStringParser(value, keys);
      }
    };

    const valueToEditor = newValue();

    const serializeValue = serializeStringNodes(value);

    const editorClass = `!min-h-[7.5rem] mt-4 mb-1 break-all pr-10 transition-all duration-150 ease-in-out ${
      (isInnerError || maxLengthError) && !disabled
        ? 'shadow-textareaShadowError active:shadow-textareaShadowActive focus:shadow-textareaShadowActiveError hover:shadow-textareaShadowActiveError innerError'
        : 'shadow-textareaShadow active:shadow-textareaShadowActive focus:shadow-textareaShadowActive hover:shadow-textareaShadowActive pr-10'
    } ${(isInnerError || maxLengthError) && !disabled ? 'placeholder-lightRed' : ''} ${
      disabled ? 'hover:!shadow-textareaShadow' : ''
    } disabled:bg-white disabled:cursor-not-allowed rounded-lg whitespace-pre-line break-words px-3 py-2 w-full`;

    return (
      <div className={`relative ${isOpen ? 'z-[19]' : ''} ${className}`}>
        {!isHideLabel ? (
          <FormLabel
            className='mb-[0.375rem]'
            htmlFor={htmlFor}
            showInfo={showInfo}
            isRequired={isRequired}
            textColor='!text-grayText'
            labelTextVariant={htmlFor}
          />
        ) : null}
        <div className='relative'>
          {isEmoji ? (
            <AddEmoji
              className={`absolute top-[6.9rem] m:top-[5.5rem] sm:top-[3.9rem] z-[19] ${
                (isInnerError || maxLengthError) && !disabled ? 'right-8' : 'right-1'
              }`}
              callback={() => emojiPickerHandler({ name: htmlFor, isOpenPicker: !isViewEmoji })}
            />
          ) : null}
          {isViewEmoji ? (
            <EmojiLib
              onEmojiClick={setEmojiInTextarea}
              className='absolute z-[101] top-[3.5rem] right-0 max-w-[350px]'
            />
          ) : null}
          <RichTextExample
            ref={childRef}
            readOnly={false}
            keys={keys}
            value={valueToEditor}
            isCheckKeys={isCheckKeys}
            onChange={onChangeHandler}
            editorClass={editorClass}
            onKeyUp={errorClassToggle(serializeValue)}
            onFocus={errorClassToggle(serializeValue)}
          />
          {(isInnerError || maxLengthError) && !disabled ? (
            <Icon
              variant='errorInInput'
              className='absolute top-[7.2rem] m:top-[5.8rem] sm:top-[4.2rem] right-[0.6rem]'
            />
          ) : null}
        </div>
        <div className='leading-[1rem] h-[1rem]'>
          {maxLength && serializeValue.length ? (
            <span className='text-grayText m-0 tracking-[0.033em] text-[12px]'>{`${serializeValue.length} / ${maxLength}`}</span>
          ) : isInnerError ? (
            <span className='text-grayText mt-[1px] mb-0 tracking-[0.033em] text-[12px]'>
              Введите текст
            </span>
          ) : null}
        </div>
        {isViewEmoji
          ? createPortal(
              <div
                onClick={onClickEmoji}
                className='absolute top-0 bottom-0 left-0 right-0 z-[18]'
              />,
              document.body,
            )
          : null}
      </div>
    );
  },
);

export const TextAreaWithSlateEditor = memo(textAreaWithSlateEditor);
