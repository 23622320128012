import { emptySettingsData } from '@data/index';
import { emptyTelephonyData, telephonyTablePageEmptyData } from '@data/mockData';
import { TSettingsInitialState } from '@redux/Settings/zod';

export const initialState: TSettingsInitialState = {
  isError: false,
  isRefresh: false,
  isLoading: false,
  isSuccess: false,
  isPosting: false,
  isPostError: false,
  isTableDataLoading: false,
  isGetTableDateError: false,
  isAdminsTableDataSaving: false,
  isAdminsTableDataSavingError: false,
  isGettingTableDataSuccess: false,
  isAdminsTableDataError: false,
  isAdminsTableDataSuccess: false,
  isAdminsTableDataLoading: false,
  isSaveSettingSuccess: false,
  isGettingStaffListError: false,
  isAccessDenied: false,
  errorToastMessage: '',
  successToastMessage: '',
  retries: 1,
  isRetries: false,
  isChurnTimeAllowEdit: false,
  churnTimeAllowEditDelay: 0,
  adminsListLength: 0,
  data: emptySettingsData,
  oldData: emptySettingsData,
  tableData: [],
  adminsTableData: [],
  adminsTableOldData: [],
  notificationsSettings: null,
  oldNotificationsSettings: null,
  isSuccessNotificationSave: false,
  isErrorNotificationSave: false,
  formStep: 0,
  webhook: '',
  manualDocs: '',
  yclientsDocs: '',
  isEditMode: false,
  connectionError: false,
  connectionSuccess: false,
  isTestingConnection: false,
  connectionTestError: false,
  connectionInProgress: false,
  connectionTestSuccess: false,
  isShowConnectionErrorMessage: false,
  isShowAlmostDoneMessage: false,
  isShowSuccessMessage: false,
  telephonyData: emptyTelephonyData,
  //
  newPaginationSize: 25,
  newPage: 0,
  firstFilterValue: 'all',
  secondFilterValue: 'all',
  sortColumnName: 'date',
  sortDirection: 'asc',
  startDate: '2023-05-20 10:00:00',
  endDate: '2023-05-28 10:00:00',
  phoneSearchString: '',
  telephonyTableData: telephonyTablePageEmptyData,
  isShowNoSaveExitModal: false,
};
