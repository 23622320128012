import React, { memo } from 'react';
//
import { useAppDispatch } from '@hooks/index';
import { TASK_DATA_KEY_NAMES } from '@const/task';
import { VisitQuantity } from '@blocks/visitQuantity';
import { TSimpleStringObjLevel2 } from '@models/index';
import { getListOfServicesTS } from '@redux/Task/thunks';
import { InputWithDropdownFromEP } from '@components/inputWithDropdownFromEP';
import {
  TDeleteBalloonSet,
  TGetCategoryListHandler,
  TVisitQuantityOnChangeHandler,
} from '@shared/types';

type TServiceEmployeeInputsProps = {
  /**
   * Массив значений фильтра сотрудников
   * @param {string[]}
   */
  staff: string[];
  /**
   * Новое значение фильтра сотрудников
   * @param {string}
   */
  newStaffValue: string;
  /**
   * Функция удаления "шарика" по его значению
   */
  deleteBalloon: TDeleteBalloonSet;
  /**
   * Функция добавления "шарика" по событию Blur
   */
  addBalloonOnBlurHandler: (name: string, value: string) => void;
  /**
   * Массив значений фильтра услуг
   * @param {string[]}
   */
  services: string[];
  /**
   * Новое значение фильтра услуг
   * @param {string}
   */
  newServicesValue: string;
  /**
   * Массив значений блока визиты, первое значение в массиве это quantityFrom, второе quantityTo
   * @param {string[]}
   */
  visits: string[];
  /**
   * Функция обработки изменения количества визитов
   */
  visitQuantityOnChangeHandler: TVisitQuantityOnChangeHandler;
  /**
   * Объект с данными сотрудников и услуг
   * @param {TSimpleStringObjLevel2}
   */
  categoriesDataList: TSimpleStringObjLevel2;
  /**
   * ID выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Флаг успешного получения данных страницы настроек
   * @param {boolean}
   */
  isSuccess: boolean;
};
export const ServiceEmployeeInputs = memo(
  ({
    staff,
    visits,
    services,
    isSuccess,
    deleteBalloon,
    newStaffValue,
    newServicesValue,
    categoriesDataList,
    selectedFilialAccId,
    addBalloonOnBlurHandler,
    visitQuantityOnChangeHandler,
  }: TServiceEmployeeInputsProps) => {
    const [quantityFrom, quantityTo] = visits;
    const dispatch = useAppDispatch();

    const getStaffList: TGetCategoryListHandler = ({ categoryName }) => {
      dispatch(getListOfServicesTS({ categoryName }));
    };

    const getServiceList: TGetCategoryListHandler = ({ categoryName }) => {
      dispatch(getListOfServicesTS({ categoryName }));
    };

    return (
      <>
        <InputWithDropdownFromEP
          disabled
          addedValues={staff}
          newValue={newStaffValue}
          isFetchingSuccess={isSuccess}
          deleteBalloon={deleteBalloon}
          inputId={TASK_DATA_KEY_NAMES.STAFF}
          getCategoryListHandler={getStaffList}
          categoriesDataList={categoriesDataList}
          selectedFilialAccId={selectedFilialAccId}
          addBalloonOnBlur={addBalloonOnBlurHandler}
          labelTextVariant={TASK_DATA_KEY_NAMES.STAFF}
          className='mt-8 max-w-[47rem]'
        />
        <InputWithDropdownFromEP
          disabled
          addedValues={services}
          newValue={newServicesValue}
          deleteBalloon={deleteBalloon}
          isFetchingSuccess={isSuccess}
          inputId={TASK_DATA_KEY_NAMES.SERVICES}
          categoriesDataList={categoriesDataList}
          getCategoryListHandler={getServiceList}
          selectedFilialAccId={selectedFilialAccId}
          addBalloonOnBlur={addBalloonOnBlurHandler}
          labelTextVariant={TASK_DATA_KEY_NAMES.SERVICES}
          className='mb-6 mt-6 max-w-[47rem]'
        />
        <VisitQuantity
          quantityTo={quantityTo || ''}
          quantityFrom={quantityFrom || ''}
          onChangeHandler={visitQuantityOnChangeHandler}
        />
      </>
    );
  },
);
