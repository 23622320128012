import React, { memo } from 'react';
//
import { statisticCalculatingPeriod } from '@const/report';
import { DropdownWithIcon } from '@components/dropdownWithIcon';

interface ICalculateDataPeriodProps {
  /**
   * Выбранный период подсчета данных
   * @param {string}
   */
  selectedDataCalculatingPeriod: string;
  /**
   * Callback для обработки изменения периода подсчета
   * @param {(period: string) => () => void}
   */
  setDataCalculatingPeriodHandler: (period: string) => () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const CalculateDataPeriod = memo(
  ({
    selectedDataCalculatingPeriod,
    setDataCalculatingPeriodHandler,
    className = '',
  }: ICalculateDataPeriodProps) => (
    <div className={`flex flex-col ${className}`}>
      <span className='font-medium text-h3_body text-black mb-4 tracking-[0.004em]'>
        Считать данные за
      </span>
      <div className='w-full max-w-[18rem]'>
        <DropdownWithIcon
          items={statisticCalculatingPeriod}
          callBack={setDataCalculatingPeriodHandler}
          selectedItem={selectedDataCalculatingPeriod}
          className='w-full max-w-[18rem] when_send_dropdown'
        />
      </div>
    </div>
  ),
);
