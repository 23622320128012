import React, { useEffect } from 'react';
//
import { getEventData } from '@helpers/index';
import { useAppSelector } from '@hooks/index';
import { NoAccess } from '@components/noAccess';
import { ErrorMessage } from '@blocks/errorMessage';
import { TPageView } from '@helpers/myTracker/type';
import { AdminsTable } from '@templates/adminsTable';
import { NoAccessWithCustom } from '@components/NoAccessWithCustom';
import telephonyExampleImg from '@img/analytics/telepfony_example.webp';
import { MY_TRACKER_EVENTS, PAGE_NAMES, postEvent } from '@helpers/myTracker/myTracker';
import { getAccountsData, getDashboardPageData, getSettingsData } from '@redux/selectors';

const AdminsTablePage = () => {
  const { selectedFilial } = useAppSelector(getAccountsData);
  const selectedFilialAccId = selectedFilial?.accId || '';
  const isProcess = !!selectedFilial?.process;

  useEffect(() => {
    postEvent<TPageView>(
      getEventData<TPageView>({
        eventName: MY_TRACKER_EVENTS.LK_PAGE_VIEW,
        eventProperties: { name_page: PAGE_NAMES.ANALYTICS_CALL_DETAILS },
      }),
    );
  }, []);

  // Получаем и деструктурезируем данные для таблицы
  const {
    isAccessDenied,
    telephonyTableData: data,
    isError: telephonyGetDataError,
  } = useAppSelector(getSettingsData);

  // Получаем и деструктурезируем данные для графика
  const { isError, isLoading, isFetchingMeta } = useAppSelector(getDashboardPageData);

  if (!isProcess && selectedFilialAccId) {
    return (
      <NoAccessWithCustom
        img={telephonyExampleImg}
        description='Функция заблокирована. Вам необходимо произвести оплату подписки.'
        header='Детализация звонков'
        link={{
          href: '/pay',
          title: 'Оплатить',
        }}
      />
    );
  }
  return (
    <>
      {!isLoading && !isError && !isFetchingMeta && !isAccessDenied && !telephonyGetDataError ? (
        <AdminsTable telephonyTableData={data} selectedFilialAccId={selectedFilialAccId} />
      ) : null}
      {isAccessDenied ? <NoAccess /> : null}
      {isError || telephonyGetDataError ? <ErrorMessage /> : null}
    </>
  );
};

export default AdminsTablePage;
