import { z } from 'zod';
import { INPUT_VARIANTS } from '@models/index';

export enum EAdminState {
  CLOSE = 'close',
  OPEN = 'open',
  NOT_ACTIVATED = 'not_activated',
}

export enum CONNECTION_TYPE {
  YCLIENTS = 'yclients',
  MANUAL = 'manual',
}

export enum SETTINGS_TELEPHONY_DATA_KEYS_NAME {
  PHONE_NUMBER = 'phoneNumber',
  API_KEY = 'apiKey',
  DOMAIN = 'domain',
}

export enum TELEPHONY_RESPONSE_KEY_NAMES {
  DATA = 'data',
  PAGINATOR = 'paginator',
}

export enum ORDER_VARIANTS {
  TASKS = 'tasks',
  STATS = 'stats',
}

export const ParamsCalcStatsSchema = z.object({
  start_rate: z.number(),
  end_rate: z.number(),
});
export const SettingsChurnSchema = z.object({
  churn_days: z.number(),
  update_allowed_at: z.string(),
});

export const GetSettingResponseDataSchema = z.object({
  call_stats: z.boolean(),
  missed_call_answer: z.boolean(),
  churn: SettingsChurnSchema,
  params_calc_stats: ParamsCalcStatsSchema,
  exception_stats_master: z.string().array(),
  use_groups: z.boolean(),
});

export const StaffListItemSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const AdminsStateResponseSchema = z.boolean().nullable();

export const AdminsPermissionSchema = z.object({
  settings: z.boolean(),
  analytics: z.boolean(),
  templates: z.boolean(),
  settings_admins: z.boolean(),
});

export const PostAdminsDataSchema = z.object({
  id: z.number(),
  state: AdminsStateResponseSchema,
  permissions: AdminsPermissionSchema,
});

export const GetSmartScheduleResponseSchema = z.object({
  id: z.number(),
  name: z.string(),
  staff_link: z.string(),
  status: z.number(),
});

export const GetAdminsTableDataResponseSchema = z.object({
  id: z.number(),
  name: z.string(),
  admin_link: z.string(),
  role: z.string(),
  state: AdminsStateResponseSchema,
  permissions: AdminsPermissionSchema,
});

export const NotificationItemSchema = z.object({
  task_created: z.boolean(),
  task_completed: z.boolean(),
  task_overdue: z.boolean(),
  task_postpone: z.boolean(),
});

export const SettingsNotificationResponseDataSchema = z.object({
  owner: NotificationItemSchema,
  performer: NotificationItemSchema,
});

export const TelephonyFieldsListItemSchema = z.object({
  name: z.string(),
  title: z.string(),
  required: z.boolean(),
  type: z.nativeEnum(INPUT_VARIANTS),
});

export const TelephonyListItemSchema = z.object({
  title: z.string(),
  webhook: z.string(),
  yclients_docs: z.string(),
  manual_docs: z.string(),
  yclients: z.boolean(),
  manual: z.boolean(),
  fields: TelephonyFieldsListItemSchema.array().nullable(),
});

export const ParamsCalcStatsDataSchema = z.object({
  startRate: z.number(),
  endRate: z.number(),
});

export const SettingsDataSchema = z.object({
  staffList: z.record(z.string(), z.string()),
  selectedStaff: z.string(),
  employeesToIgnore: z.string().array(),
  missedCallAnswer: z.boolean(),
  customerChurnTime: z.string(),
  churnTimeAllowUpdate: z.string(),
  callStats: z.boolean(),
  paramsCalcStats: ParamsCalcStatsDataSchema,
  useGroups: z.boolean(),
});

export const PostSettingsRequestDataSchema = z.object({
  data: SettingsDataSchema,
});

export const SettingsStaffSchema = z.object({
  id: z.number(),
  name: z.string(),
  staffLink: z.string(),
  status: z.number(),
});

export const AdminStateSchema = z.nativeEnum(EAdminState);

export const AdminsListItemSchema = z.object({
  id: z.number(),
  name: z.string(),
  adminRights: z.string().array(),
  adminLink: z.string(),
  state: AdminStateSchema,
});

export const OperatorsDataSchema = z.object({
  name: z.string(),
  webhook: z.string(),
  yclientsDocs: z.string(),
  manualDocs: z.string(),
  yclients: z.boolean(),
  manual: z.boolean(),
  fields: TelephonyFieldsListItemSchema.array().nullable(),
});

export const PossibleConnectionTypesSchema = z.object({
  yclients: z.boolean(),
  manual: z.boolean(),
});

export const ConnectionTypeSchema = z.nativeEnum(CONNECTION_TYPE);

export const SettingsTelephonyDataTestSchema = z
  .object({
    connectionStatus: z.boolean(),
    selectedOperator: z.string(),
    phoneNumber: z.string(),
    operators: OperatorsDataSchema.array(),
    possibleConnectionTypes: PossibleConnectionTypesSchema,
    connectionType: ConnectionTypeSchema,
    branchId: z.number(),
  })
  .and(
    z.record(
      z.string(),
      z.union([
        z.string(),
        z.boolean(),
        z.number(),
        OperatorsDataSchema.array(),
        PossibleConnectionTypesSchema,
        ConnectionTypeSchema,
      ]),
    ),
  );

export const SettingsTelephonyResponseDataSchema = z
  .object({
    name: z.string(),
    title: z.string(),
    telnum: z.string(),
  })
  .and(
    z.record(
      z.string(),
      z.union([
        z.string(),
        z.boolean(),
        z.number(),
        OperatorsDataSchema.array(),
        PossibleConnectionTypesSchema,
        ConnectionTypeSchema,
      ]),
    ),
  );

export const SettingsTelephonyDataSchema = z
  .object({
    connectionStatus: z.boolean(),
    selectedOperator: z.string(),
    phoneNumber: z.string(),
    operators: OperatorsDataSchema.array(),
    possibleConnectionTypes: PossibleConnectionTypesSchema,
    connectionType: ConnectionTypeSchema,
  })
  .and(
    z.record(
      z.string(),
      z.union([
        z.string(),
        z.boolean(),
        z.number(),
        OperatorsDataSchema.array(),
        PossibleConnectionTypesSchema,
        ConnectionTypeSchema,
        SettingsTelephonyResponseDataSchema,
      ]),
    ),
  );

export const TelephonyRequestDataSchema = z.object({
  start_date: z.string(),
  end_date: z.string(),
  page: z.number(),
  size: z.number(),
  sort: z.string(),
  reverse: z.boolean().optional(),
  filter: z.string(),
  phone: z.string(),
});

export const TelephonyPaginatorSchema = z.object({
  count: z.number(),
  total_pages: z.number(),
  current_page: z.number(),
  size: z.number(),
});

export const TelephonyPaginationData = z.object({
  count: z.number(),
  totalPages: z.number(),
  currentPage: z.number(),
  size: z.number(),
});

export const TelephonyTableDataSchema = z.object({
  date: z.string(),
  type: z.string(),
  phone: z.string(),
  name: z.string(),
  status: z.string(),
  link: z.string().nullable(),
  record: z.string().nullable(),
});

export const TelephonyResponseSchema = z.object({
  paginator: TelephonyPaginatorSchema,
  data: TelephonyTableDataSchema.array(),
});

export const TelephonyTablePageDataSchema = z.object({
  paginator: TelephonyPaginationData,
  data: TelephonyTableDataSchema.array(),
});

export const StaffDataSchema = z.object({
  admins: StaffListItemSchema.array(),
  masters: StaffListItemSchema.array(),
});

export const StaffListResponseSchema = z.object({
  data: StaffDataSchema,
});

export const SettingsTelephonyDataResponseSchema = z.object({
  telephony: SettingsTelephonyResponseDataSchema.nullable(),
  telephony_list: TelephonyListItemSchema.array(),
});

export type TTelephonyTablePageData = z.infer<typeof TelephonyTablePageDataSchema>;

export type TTelephonyTableData = z.infer<typeof TelephonyTableDataSchema>;

export type TTelephonyPaginationData = z.infer<typeof TelephonyPaginationData>;

export type TTelephonyPaginator = z.infer<typeof TelephonyPaginatorSchema>;

export type TTelephonyResponse = z.infer<typeof TelephonyResponseSchema>;

export type TTelephonyRequestData = z.infer<typeof TelephonyRequestDataSchema>;

export type TSettingsTelephonyResponseData = z.infer<typeof SettingsTelephonyResponseDataSchema>;

export type TSettingsTelephonyDataTest = z.infer<typeof SettingsTelephonyDataTestSchema>;

export type TConnectionType = z.infer<typeof ConnectionTypeSchema>;

export type TPossibleConnectionTypes = z.infer<typeof PossibleConnectionTypesSchema>;

export type TOperatorsData = z.infer<typeof OperatorsDataSchema>;

export type TSettingsTelephonyData = z.infer<typeof SettingsTelephonyDataSchema>;

export type TAdminState = z.infer<typeof AdminStateSchema>;

export type TAdminsListItem = z.infer<typeof AdminsListItemSchema>;

export type TSettingStaff = z.infer<typeof SettingsStaffSchema>;

export type TParamsCalcStats = z.infer<typeof ParamsCalcStatsDataSchema>;

export type TSettingsData = z.infer<typeof SettingsDataSchema>;

export type TPostSettingsRequestData = z.infer<typeof PostSettingsRequestDataSchema>;

export type TSettingsChurn = z.infer<typeof SettingsChurnSchema>;

export type TGetSettingResponseData = z.infer<typeof GetSettingResponseDataSchema>;

export type TStaffListItem = z.infer<typeof StaffListItemSchema>;

export type TStaffData = z.infer<typeof StaffDataSchema>;

export type TStaffListResponse = z.infer<typeof StaffListResponseSchema>;

export type TAdminStateResponse = z.infer<typeof AdminsStateResponseSchema>;

export type TAdminsPermission = z.infer<typeof AdminsPermissionSchema>;

export type TPostAdminsData = z.infer<typeof PostAdminsDataSchema>;

export type TGetSmartScheduleResponse = z.infer<typeof GetSmartScheduleResponseSchema>;

export type TGetAdminsTableDataResponse = z.infer<typeof GetAdminsTableDataResponseSchema>;

export type TNotificationItem = z.infer<typeof NotificationItemSchema>;

export type TSettingsNotificationResponseData = z.infer<
  typeof SettingsNotificationResponseDataSchema
>;

export type TTelephonyFieldsListItem = z.infer<typeof TelephonyFieldsListItemSchema>;

export type TTelephonyListItem = z.infer<typeof TelephonyListItemSchema>;

export type TSettingsTelephonyDataResponse = z.infer<typeof SettingsTelephonyDataResponseSchema>;
